.landingPageWrapp .conttColInfo h4 {
    font-size: 24px;
    margin-top: 0;
    margin-bottom: 10px;
}
.landingPageWrapp .conttColInfo.noTitle h4 {
    display: none;
}
.landingPageWrapp .conttColInfo p {
    font-size: 20px;
    line-height: 34px;
    font-family: 'Medium' !important;
}
.landingPageWrapp .conttColInfo p a {
    color: var(--secondary);
    font-family: 'Bold' !important;
}

.landingPageWrapp .conttColIcon i > img {
    width: 80px;
    height: 80px;
    border-radius: 50px;
}
