.conversation-search {
  display: flex;  
  justify-content: space-between;
  border: 2px solid #BFD4E5;
  border-radius: 40px;  
  padding: 5px 10px;  
}

.conversation-search-input {
  border: none;    
  width: 90%;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  text-align: left;  
}

.conversation-search-input::placeholder{
  color: #afbbc6;
}
.conversation-search-icon{
  color: #afbbc6;
  padding:5px ;
  cursor: pointer;
}
.search-members{
  max-width: 80%;
}