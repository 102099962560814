.react-time-picker__wrapper{
    border-radius: 10px;
    padding: 0.3rem 0 0.3rem 0.8rem;
    border: thin solid #cccccc;
}

.worklog-input .react-time-picker{
    background: #F9FAF5;;
    color: #565656;    
    font-weight: 600;
    border-radius: 10px;
}
 

.react-time-picker__inputGroup {
    display: flex;
    align-items: baseline;
    input {
        font-weight: 600;
    }
    span {
        font-weight: 600;
    }
    select {
        font-weight: 600;
    }
}
.react-time-picker--disabled {
    border-radius: 10px;
}