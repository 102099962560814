.custom-multi-select .dropdown-container {
    background-color: #F9FAF5 !important;
    border-radius: 10px !important;

    >.dropdown-heading {
        cursor: pointer;

        >.dropdown-heading-value span {
            font-weight: 600;
            padding-left: 2px;
        }
    }
}

.custom-multi-select {
    &.__disabled {
        >.dropdown-container {
            background-color: #9398AE !important;

            >.dropdown-heading {
                cursor: no-drop;
                color: #fff;

                button {
                    cursor: no-drop;
                }
            }
        }
    }
}

.users-chat .custom-multi-select .dropdown-container {
    border-radius: 50px !important;
}

.custom-multi-select-max-height-100 ul {
    max-height: 100px !important;
}

.custom-multi-select-max-height-150 ul {
    max-height: 150px !important;
}

.custom-multi-select-max-height-200 ul {
    max-height: 200px !important;
}

.custom-select-max-height-200 .react-select__menu-list {
    max-height: 200px !important;
}

.custom-select-max-height-110 .react-select__menu-list {
    max-height: 110px !important;
}