@import url('https://fonts.googleapis.com/css2?family=Exo+2:wght@270&display=swap');

.smallSvg {
    height: 18.095577239990234px !important;
    width: auto !important;
    left: 0px;
    top: 0px;
    border-radius: 0px;
    margin-right: 5px !important;
}

.userIconManage {
    width: 32px !important;
    height: 32px;
    border-radius: 50% !important;
    margin-right: 8px;
}


.notify-title {
    width: 100%;
    height: fit-content;
    background-color: #2550AC;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    color: #fff;
    font-size: 14px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.userStoreName {
    display: inline-block !important;

}

.userName {
    margin: 0px !important;
}

.marginRemove {
    margin: 0 !important;
    padding: 0 !important;
}

.lineAdminNav {
    color: rgba(205, 215, 235, 1);
    margin-top: 8px;
    background: black;
    height: 1px !important;
}

.flexMainNavWrap {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    align-content: center;
    justify-content: center;
}

.userProfileWrapper {
    margin-left: 5px;
    border-left: 2px solid #BFD4E5;

}

.removeEverything {
    margin: 0px !important;
    padding: 0px !important;
    min-width: inherit !important;
    min-height: inherit !important;
}


.MsgAdminNavWrapper {
    padding: 8px !important;
    border: 1px solid rgba(208, 213, 222, 1);
    border-radius: 19px !important;
    margin-right: 12px;
}

.adminNavButtons {
    padding: 4px 8px !important;
    display: flex;
    margin-right: 14px !important;
    align-items: center;
    justify-content: center;
    height: auto !important;
    border: none !important;
    border-radius: 22px !important;
    font-size: 14px;
    font-weight: 600;
    color: #1B1B1B;
}

.AdminDropdown a {
    text-decoration: none;
    color: black;
    font-weight: 600;
    font-size: 14px;
}

.addValueDropactive {
    font-size: 14px;
}

.AdminDropdown a:hover {
    color: rgba(37, 80, 172, 1) !important;
}

.addValueDrop {
    display: flex;
    align-items: center;
    justify-content: flex-start;

}

.userName p {
    font-size: 14px;
    font-weight: 600;
    margin: 0px !important;
    padding: 0px !important;
    color: black !important;
    max-width: fit-content !important;
    margin-right: 4px !important;

}

.userDropAdmin .dropdown-menu {
    opacity: 1 !important;
    background-color: white !important;
}


.main-panel {
    background: #F9FAF5 !important;
}

.ListMain a {
    text-decoration: none !important;
}


.ListMain .active {
    background: rgba(37, 80, 172, 1) !important;
    color: white !important;
}

.addDropMenu .active {
    background: rgba(37, 80, 172, 1) !important;
    color: white !important;
    opacity: 1 !important;
}

.adminNavButtons:hover {
    background: rgba(37, 80, 172, 1) !important;
    color: #fff !important;
}

.adminNavButtons span {
    font-size: 14px;
    font-weight: 600;
    /* line-height: 20px; */
    /* text-align: left; */
    color: #1B1B1B;
}

.adminNavButtons:hover span {
    color: white !important;
}

.iconDropAdminNav {

    margin-left: 4px !important;
    width: 12px !important;
}

.JustMargin {
    margin-top: 4px !important;
    width: 12px !important;
    margin-left: 8px !important;
}

.borderRemove {
    border: none !important;
}

.flexMainNavWrap .dropdown-menu {
    margin-top: 3px;
}

.borderRemove .dropdown-item:hover {
    background: transparent !important;
}

.userDropAdmin .dropdown-toggle {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
}

.innerFlex {
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

.innerFlex:hover {
    color: rgba(37, 80, 172, 1) !important;
    cursor: pointer;
}

.innerFlex span {
    margin: 3px 6px !important;
    font-weight: 600;
}

.ListMain {
    display: flex;
    justify-content: center !important;
    flex-wrap: wrap;
    align-items: center;
}

.MainWrapperAdmin {
    background: #fff;
}

.is-sticky {
    position: sticky;
    top: 0;
    z-index: 999;
    box-shadow: 5px 5px 5px 0px rgb(0 0 0 / 15%);
    background-color: #ffffff !important;
    animation: 500ms ease-in-out 0s normal none 1 running fadeInDown;
}

.ListMain {
    justify-content: flex-end !important;
}

.languageBtn .dropdown-toggle::after {
    content: url("../../assets/img/new_design/jsx_adminnavbar_svg/language_dropdown.svg") !important;
    border: none !important;
    vertical-align: middle !important;
    margin: 0px 0.35rem;
    width: 12px !important;

}

.flexMainNavWrap .show .dropdwon-toggle::after {
    content: none;
}

.userDropAdmin .dropdown-toggle::after {
    content: url("../../assets/img/new_design/jsx_adminnavbar_svg/language_dropdown.svg") !important;
    border: none !important;
    vertical-align: middle !important;
    margin: 0px 0.65rem;

    width: 12px !important;

}

.addDropdownMain .dropdown-toggle::after {
    content: "New" !important;
    border: none !important;
    vertical-align: middle !important;
    margin: 0px 0.35rem;
    font-size: 12px;
    font-weight: 600 !important;
    width: 12px !important;
}

.addDropdownMain {
    margin-right: 12px !important;

}

/* to remove and background color from inbuilt bootstrap css */
.header-common-dropdwn-style,
.header-lang-dropdown .btn.show {
    color: none;
    background-color: #fff;
    border-color: none;
}

.notify-dropdown .btn.show {
    color: none;
    background-color: #fff;
    border-color: none;
}

.notify-dropdown .btn:focus {
    color: #2550AC !important;
    border-color: #2550AC !important;
}

.notify-dropdown .btn:hover {
    color: #2550AC !important;
    border-color: #2550AC !important;
}

.header-common-dropdwn-style,
.header-lang-dropdown .btn-primary:hover {
    color: inherit;
    border-color: #BFD4E5;
}

.header-common-dropdwn-style,
.header-lang-dropdown .btn-primary:focus {
    color: inherit;
    border-color: #BFD4E5;
}

.header-common-dropdwn-style,
.header-lang-dropdown .btn-primary:active {
    color: inherit;
    border-color: #BFD4E5;
}

.header-common-dropdwn-style,
.header-lang-dropdown .btn-primary:active:focus {
    color: inherit;
    border-color: #BFD4E5;
}

.header-common-dropdwn-style,
.header-lang-dropdown .btn-primary {
    border-color: #fff !important;
    color: rgb(193, 184, 184) !important;
}

/* override  bootstrap btn box shadow */
.header-lang-dropdown .btn.show:focus-visible,
.btn:first-child:active:focus-visible {
    box-shadow: none !important;
}

.header-lang-dropdown .dropdown-toggle::after {
    content: url("../../assets/img/new_design/jsx_adminnavbar_svg/language_dropdown.svg") !important;
    border: none !important;
    vertical-align: middle !important;
    margin: 0px 0.5rem;
    width: 12px !important;
}


.header-lang-dropdown .show .dropdown-toggle::after {
    transform: rotate(180deg);
}

.header-lang-dropdown .dropdown-header {
    background-color: #2550AC;
    color: #fff;
    font-size: 14px;
    border-radius: 10px;
    padding: 10px 20px;
    font-weight: 600;
}

.header-common-dropdwn-style .dropdown-toggle {
    border: 1px solid #BFD4E5;
    width: 40px !important;
    line-height: 44px;
    height: 40px;
    text-align: center;
    border-radius: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.header-common-dropdwn-style .dropdown-toggle::after {
    /* To remove caret sign */
    content: none;
}

.header-common-dropdwn-style .text {
    position: absolute;
    left: 52px;
    font-size: 15px;
    color: #1B1B1B;
    font-weight: 600;
}

.header-common-dropdwn-style .feature-first-child {
    width: 100%;
    height: 5px;
    background-color: #2550AC;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
}

.header-common-dropdwn-style .dropdown-menu::before {
    border-bottom: 11px solid rgba(164, 15, 15, 0.2);
    border-left: 11px solid rgba(152, 38, 38, 0);
    border-right: 11px solid rgba(180, 19, 19, 0);
    content: "";
    display: inline-block;
    position: absolute;
    left: 12px;
    top: -11px;
}

.header-common-dropdwn-style .dropdown-menu::after {
    border-bottom: 11px solid #2550AC;
    border-left: 11px solid rgba(115, 21, 21, 0);
    border-right: 11px solid rgba(185, 42, 42, 0);
    content: "";
    display: inline-block;
    position: absolute;
    left: 12px;
    top: -10px;
}

.header-lang-dropdown .dropdown-menu::before {
    border-bottom: 11px solid rgba(164, 15, 15, 0.2);
    border-left: 11px solid rgba(152, 38, 38, 0);
    border-right: 11px solid rgba(180, 19, 19, 0);
    content: "";
    display: inline-block;
    position: absolute;
    left: 150px;
    top: -11px;
}

.header-lang-dropdown .dropdown-menu::after {
    border-bottom: 11px solid #2550AC;
    border-left: 11px solid rgba(115, 21, 21, 0);
    border-right: 11px solid rgba(185, 42, 42, 0);
    content: "";
    display: inline-block;
    position: absolute;
    left: 150px;
    top: -10px;
}

.header-lang-dropdown .languages-list .language-item {
    display: flex;
    border-bottom: 1px solid #BFD4E5;
    align-items: center;
    padding: 10px 16px;
    min-height: 42px;
    font-weight: 600;
}

.header-lang-dropdown .languages-list .language-item .lang-logo {
    margin-right: 14px;
}

.header-lang-dropdown .languages-list .active {
    color: #2550AC;
    text-decoration: none;
    background-color: #fff;
    font-weight: 600;
}

.adminNavButtons:nth-of-type(2) {
    width: auto !important;
}

.header-functions-menu .header-functions-menu-item {
    font-size: 14px;
    line-height: 44px;
    border-radius: 10px;
    padding: 0 18px;
    font-weight: 600;
    color: #1B1B1B;
    display: inline-block;
    text-decoration: none;
}



.header-functions-menu .header-functions-menu-item-active {
    background-color: #2550AC;
    color: #fff;
    font-size: 14px;
    line-height: 44px;
    border-radius: 10px;
    padding: 0 18px;
    font-weight: 600;
    display: inline-block;
    text-decoration: none;
    margin-left: 10px;
}

.header-functions-menu .header-functions-menu-item-mobile-active {
    color: #2550AC;
    font-size: 10px;
    border-radius: 10px;
    line-height: 14px;
    font-weight: 600;
    display: inline-block;
    text-decoration: none;
    max-width: 60px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.header-functions-menu .header-functions-menu-item-active .header-functions-img {
    filter: brightness(100) !important;
    color: white !important;
}


.header-functions-menu .header-functions-menu-item-mobile-active .header-functions-img-mobile {
    color: white !important;
}

.header-functions-menu .header-functions-menu-item:hover {
    background-color: #2550AC;
    color: #fff;
    font-size: 14px;
    line-height: 44px;
    border-radius: 10px;
    padding: 0 18px;
    font-weight: 600;
    display: inline-block;
    text-decoration: none;
}


.header-functions-menu .header-functions-img {
    display: inline-block;
    margin-right: 5px;
    position: relative;
    top: -2px;

}

.header-functions-menu .header-functions-menu-item:hover .header-functions-img {
    filter: brightness(100) !important;
    color: white !important;
}

.header-functions-menu .header-functions-img-mobile {
    display: inline-block;
    margin-right: 5px;
    position: relative;
    top: -2px;
}

.MainWrapperAdmin .col-custom-auto {
    flex: 0 0 auto;
    width: auto;
}

.toggle-sidebar-button {
    background-color: #fff;
    width: 46px;
    height: 46px;
    border-radius: 46px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.toggle-sidebar-button button.btn-menubar {
    padding: 0;
    margin: 0;
    display: inline-block;
    width: 25px;
    height: 25px;
    transition: all 0.4s ease 0s;
    background: transparent;
    border: 0;
}

.toggle-sidebar-button .btn-menubar span {
    background: #2550AC;
    width: 25px;
    height: 2px;
    border-radius: 10px;
    display: block;
    transition: all 0.4s ease 0s;
}

.toggle-sidebar-button .btn-menubar span.bar2 {
    margin: 5px 0;
}

.MainWrapperAdmin .d-none {
    display: none;
}

.notifications-offcanvas .offcanvas-header {
    position: relative;
}

.notifications-offcanvas .offcanvas-body {
    padding: 10px 0px;
}

.notifications-offcanvas .notification-item {
    display: flex;
    padding: 10px;
    text-decoration: none;
    border-bottom: 1px solid #cccccc;
    position: relative;
    align-items: center;

    &&.unread {
        position: absolute;
        top: 0;
        left: 0;
        width: 0;
        height: 0;
        padding: 0;
        border-bottom: 20px solid transparent;
        border-left: 20px solid #3158ac;

    }

    &&.read {
        position: absolute;
        top: 0;
        left: 0;
        width: 0;
        height: 0;
        padding: 0;
        border-bottom: 20px solid transparent;
        border-left: 20px solid #BFD4E5;
    }
}

.notifications-offcanvas .notification-item p {
    margin-bottom: 0px !important;
}

.notifications-offcanvas .notification-item .room-name,
.msg {
    color: #1B1B1B !important;
}

.notifications-offcanvas .notification-item .chat-notifications-list .timestamp {
    font-size: 14px;
    color: #9BA7BF;
    font-weight: 300;
    display: inline-block;
    line-height: 14px;
}

.notifications-offcanvas .offcanvas-header .h5 {
    margin-bottom: 0px !important;
    font-weight: 600;
    font-size: 20px;
}

.notifications-offcanvas .btn-clear-notification {
    position: absolute;
    right: 15%;
}

.header-common-dropdwn-style {
    &.multi-account-dropdown-container {
        >.dropdown {

            >.btn-check:checked+.btn,
            .btn.active,
            .btn.show,
            .btn:first-child:active,
            :not(.btn-check)+.btn:active {
                color: #fff;
                opacity: 1;
                background-color: #2550AC !important;
            }

            >.dropdown-menu {
                inset: 10px auto auto auto !important;
                min-width: 180px;

                >.dropdown-header {
                    background-color: #2550AC;
                    border-top-right-radius: 10px;
                    border-top-left-radius: 10px;
                    border-bottom: 1px solid #fff;
                    padding: 10px;
                }

                >.dropdown-header>div>span {
                    color: #fff;
                    font-size: 14px;
                    font-weight: 600;
                    border-radius: 5px;
                    border: 1px solid #fff;
                    padding: 0 5px;
                    cursor: pointer;
                }

                >.dropdown-header>div>span:hover,
                span:focus,
                span:active {
                    background-color: #fff;
                    color: #2550AC;
                }

                >.dropdown-item {
                    color: #2550AC;
                    font-size: 13px;
                    font-weight: 600;
                    border-radius: 0;

                    >.multi-account-dropdown-item {
                        display: flex;
                        justify-content: space-between;
                        align-items: center;

                        >.account {
                            font-weight: 600;
                            margin-right: 2em;
                        }

                        >.account-status {
                            font-size: 10px;
                        }
                    }
                }

                >button.dropdown-item:hover,
                button.dropdown-item:focus,
                button.dropdown-item:active {
                    background-color: #2550AC !important;
                    color: #fff !important;
                    opacity: 1;

                    &:last-child {
                        border-bottom-right-radius: 10px;
                        border-bottom-left-radius: 10px;
                    }
                }
            }
        }
    }
}

/* here goes the miner media queries */
@media screen and (min-width:150px) and (max-width:550px) {
    .ListMain {
        justify-content: flex-start !important;
        margin-left: 12px !important;
    }

    .userProfileWrapper {
        justify-content: flex-start !important;
        margin-left: 5px;

    }

    .userName p {
        font-size: 10px;


    }

    .adminNavButtons {
        justify-content: flex-start;
        margin-left: 12px !important
    }

    .userDropAdmin .dropdown-menu {
        inset: auto auto auto auto !important;
        margin-top: 15px !important;
    }

    .userDropAdmin .dropdown-toggle {
        justify-content: flex-start !important;
        align-items: flex-start !important;
        padding: 0px !important;
    }

    .flexMainNavWrap {
        justify-content: flex-start;
    }

    .addDropdownMain {
        margin: 0px 0px 14px 0px !important;
    }

    .borderRemove {
        padding: 0px !important;
    }

    .languageBtn {
        margin: 0px 0px 14px 0px !important;
    }

    .ListMain {
        margin: 0px !important;
    }

    .MsgAdminNavWrapper {
        margin-bottom: 4px !important;
    }

}

/* Media Queries for new header */
@media screen and (min-width:768px) {
    .header-common-dropdwn-style .notify-dropdown .dropdown-menu {
        width: 280px;
        inset: 10px 3px auto auto !important;
    }

    .header-lang-dropdown .dropdown-menu {
        width: 200px;
        inset: 10px 3px auto auto !important;
    }

    .userProfileWrapper .user-profile-dropdown .dropdown-menu {
        width: 220px;
        /* inset: 10px 3px auto auto !important; */
    }

    .header-common-dropdwn-style .notify-dropdown .dropdown-menu::before {
        border-bottom: 11px solid rgba(164, 15, 15, 0.2);
        border-left: 11px solid rgba(152, 38, 38, 0);
        border-right: 11px solid rgba(180, 19, 19, 0);
        content: "";
        display: inline-block;
        position: absolute;
        left: 251px;
        top: -11px;
    }

    .header-common-dropdwn-style .notify-dropdown .dropdown-menu::after {
        border-bottom: 11px solid #3158ac;
        border-left: 11px solid rgba(115, 21, 21, 0);
        border-right: 11px solid rgba(185, 42, 42, 0);
        content: "";
        display: inline-block;
        position: absolute;
        left: 251px;
        top: -10px;
    }

    .header-common-dropdwn-style .notify-dropdown .dropdown-header {
        background-color: #2550AC;
        color: #fff;
        font-size: 14px;
        border-top-right-radius: 10px;
        border-top-left-radius: 10px;
        padding: 10px 20px;
        font-weight: 600;
    }

    .header-common-dropdwn-style .notify-dropdown .dropdown-header .notify-view-all {
        color: #ffee1d;
        text-decoration: underline;
        cursor: pointer;
    }

    .notify-dropdown .notify-count-number {
        position: absolute;
        right: -5px;
        top: -12px;
        font-size: 10px;
        font-weight: 600;
        height: 27px;
        width: 27px;
        line-height: 23px;
        background: #447DF7;
        color: #fff;
        border-radius: 50%;
        text-align: center;
        border: 2px solid #BFD4E5;
        animation: notify-count-animation 1.6s infinite;
    }

    @keyframes notify-count-animation {
        0% {
            -webkit-box-shadow: 0 0 0 0 #BFD4E5;
            -moz-box-shadow: 0 0 0 0 #BFD4E5;
            box-shadow: 0 0 0 0 #BFD4E5
        }

        100% {
            -webkit-box-shadow: 0 0 0 3px #BFD4E5;
            -moz-box-shadow: 0 0 0 3px #BFD4E5;
            box-shadow: 0 0 0 3px #BFD4E5
        }
    }

    .notify-dropdown .notification-list {
        max-height: 384px;
    }

    .notify-dropdown .notify-item {
        display: flex;
        border-bottom: 1px solid #BFD4E5;
        align-items: center;

        &&.read {
            border-left: 5px solid #ffffff;
            opacity: 0.6;
        }

        &&.unread {
            border-left: 5px solid #2550AC;
            background: #deeefb;
        }
    }

    .notify-dropdown .notify-item:last-child {
        border-bottom-left-radius: 7px;
    }

    .notify-dropdown .dropdown-item:active {
        color: inherit !important;
        background-color: inherit !important;
    }


    .notify-dropdown .notify-symbol {
        margin-right: 14px;
    }

    .notify-dropdown .notify-symbol i {
        height: 40px;
        width: 40px;
        font-size: 14px;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .notify-dropdown .notify-text {
        overflow: hidden;
    }

    .notify-dropdown .notify-text p {
        font-size: 14px;
        color: #1B1B1B;
        line-height: 20px;
        margin-bottom: 0;
        font-weight: 600;
        white-space: break-spaces;
    }

    .notify-dropdown .notify-text span {
        font-size: 11px;
        color: #9BA7BF;
        font-weight: 300;
        display: inline-block;
        line-height: 14px;
    }

    .notify-dropdown .notify-text span.msg {
        display: block;
        font-size: 12px;
        color: #1B1B1B;
        line-height: 22px;
        margin-bottom: 4px;
        font-weight: 400;
    }

    .clear-btn {
        padding: 0 3px;
        border: 2px solid;
        border-radius: 5px;
    }

    .user-profile-dropdown .btn-primary {
        background: #fff !important;
        color: #1B1B1B !important;
        border-color: #fff !important;
    }

    .user-profile-dropdown .user-name {
        font-size: 14px;
        font-weight: 600;
        color: #1B1B1B;
    }

    .userProfileWrapper .dropdown-toggle::after {
        content: url("../../assets/img/new_design/jsx_adminnavbar_svg/language_dropdown.svg") !important;
        border: none !important;
        vertical-align: middle !important;
        margin: 0px 10px;
        width: 12px !important;
    }

    .userProfileWrapper .show .dropdown-toggle::after {
        transform: rotate(180deg);
    }

    .userProfileWrapper .user-profile-dropdown .dropdown-header {
        background-color: #2550AC;
        color: #fff;
        font-size: 14px;
        border-radius: 10px;
        padding: 10px 20px;
        font-weight: 600;
    }

    .userProfileWrapper .user-profile-dropdown .dropdown-header .user-name {
        font-size: 15px;
        font-weight: 600;
        color: #fff;
    }

    .userProfileWrapper .user-profile-dropdown .dropdown-header .user-role {
        display: inline-block;
        opacity: 0.7;
        line-height: 16px;
        font-size: 14px;
        letter-spacing: 0;
        color: #fff;
    }

    .userProfileWrapper .user-profile-dropdown .dropdown-item {
        border-bottom: 1px solid #BFD4E5;
        padding: 0px;
    }

    .userProfileWrapper .user-profile-dropdown .dropdown-item:active {
        color: inherit;
        background-color: inherit;
    }

    .userProfileWrapper .user-profile-dropdown .user-profile-item {
        text-decoration: none;
        color: #212529;
        font-weight: 600;
        font-size: 14px;
        padding: 8px 20px;
    }

    .userProfileWrapper .user-profile-dropdown .user-profile-item i {
        display: inline-block;
        margin-right: 14px;
        font-size: 20px;
        color: #9BA7BF;
        width: 16px;
    }

    .userProfileWrapper .user-profile-dropdown .dropdown-menu::before {
        border-bottom: 11px solid rgba(164, 15, 15, 0.2);
        border-left: 11px solid rgba(152, 38, 38, 0);
        border-right: 11px solid rgba(180, 19, 19, 0);
        content: "";
        display: inline-block;
        position: absolute;
        left: 180px;
        top: -11px;
    }

    .userProfileWrapper .user-profile-dropdown .dropdown-menu::after {
        border-bottom: 11px solid #3158ac;
        border-left: 11px solid rgba(115, 21, 21, 0);
        border-right: 11px solid rgba(185, 42, 42, 0);
        content: "";
        display: inline-block;
        position: absolute;
        left: 180px;
        top: -10px;
    }

    .MainWrapperAdmin .col-md {
        flex: 1 0 0%;
    }

    .MainWrapperAdmin .col-md-auto {
        flex: 0 0 auto;
        width: auto;
        padding-right: 0px;
    }

}

@media (max-width: 991px) {
    .header-functions-menu {
        background: #fff;
        position: fixed;
        left: 0;
        right: 0;
        bottom: 0;
        box-shadow: 12px 4px 16px rgb(0 0 0 / 9%);
        padding: 10px 0px;
        z-index: 9;
        display: flex;
        align-items: center;
        justify-content: space-around;
    }

    .header-functions-menu .header-functions-menu-item {
        opacity: 0.5;
        color: #2550AC;
        font-size: 10px;
        line-height: 14px;
        padding: 0px;
        overflow: hidden;
        max-width: 60px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        text-overflow: ellipsis;
    }

    .header-functions-menu .header-functions-img {
        text-align: center;
        display: block;
        width: 100%;
    }

    .header-functions-menu .header-functions-img-mobile {
        text-align: center;
        display: block;
        width: 100%;
    }

}

@media (min-width: 992px) {
    .MainWrapperAdmin .d-custom-lg-flex {
        display: flex !important;
    }
}

@media (max-width:767px) {
    .header-lang-dropdown .dropdown-menu::before {
        border-bottom: 11px solid rgba(164, 15, 15, 0.2);
        border-left: 11px solid rgba(152, 38, 38, 0);
        border-right: 11px solid rgba(180, 19, 19, 0);
        content: "";
        display: inline-block;
        position: absolute;
        left: 130px;
        top: -11px;
    }

    .header-lang-dropdown .dropdown-menu::after {
        border-bottom: 11px solid #2550AC;
        border-left: 11px solid rgba(115, 21, 21, 0);
        border-right: 11px solid rgba(185, 42, 42, 0);
        content: "";
        display: inline-block;
        position: absolute;
        left: 130px;
        top: -10px;
    }
}

/* Hide Sidebar */
@media (min-width: 1300px) {
    .MainWrapperAdmin .d-custom-xl-none {
        display: none;
    }

    .MainWrapperAdmin .d-custom-xl-inline-block {
        display: inline-block !important;
    }
}


@media (max-width :1300px) {
    .header-common-dropdwn-style {
        &.multi-account-dropdown-container {
            >.dropdown {
                >.dropdown-menu {
                    inset: 10px -15px auto auto !important;
                    &::after,
                    &::before {
                        left: 135px!important;
                    }
                }
            }
        }
    }
}