* {
    background-color: transparent;
}

.custom-transparent {
    background-color: transparent !important;
    border: 0px !important;
}

.timelog-action-block-new {
    background: #E7ECF4;
    padding: 24px;
    text-align: center;
    border-radius: 12px;
    max-width: 700px;
    margin: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.timelog-action-block-label {
    flex-grow: 1;
    padding-right: 10px;
    font-weight: 600;
    font-size: 20px;
    line-height: 22px;
    letter-spacing: 0.75px;
    color: #2550AC;
}

.timelog-grid-time-card {
    margin-left: 0;
    margin-right: 0;
    width: 100%;
}

.register-distance-input {
    width: 170px;
    border-radius: 10px;
    border: 1px solid #BFD4E5;
}

.card-timelog-grid-item {
    background: #EFF2F6;
    border: 1px solid #BFD4E5;
    border-radius: 10px;
}

.timelog-grid-overtime-item {
    display: flex;
    padding: 8px;
}

.timelog-grid-extra-payment-item {
    display: flex;
    padding: 8px;
}

.timelog-grid-extra-payment-item_approval {
    display: flex;
    padding: 4px;
}

.timelog-grid-extra-payment-item_approval .input-block__base-input {
    height: 40px;
}

.timelog-action-block-button {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 1rem !important
}

.timelog-grid-item-1 {
    display: flex;
    padding: 12px;
    border-right: 0px !important;
    border-top-right-radius: 0px !important;
    border-bottom-right-radius: 0px !important;
    margin-bottom: 1.5rem !important;
}

.timelog-grid-item-2 {
    display: flex;
    padding: 12px;
    border-top-left-radius: 0px !important;
    border-bottom-left-radius: 0px !important;
    margin-bottom: 1.5rem !important;
}

.timelog-grid-gps-card {
    margin-left: 0;
    margin-right: 0;
    background-color: #E6EAF2;
    border-radius: 10px;
    /* display: flex; */
}

.timelog-grid-gps-item {
    display: flex;
    align-items: center;
    padding: 12px;
    border-right: 1px solid #BFD4E5;
}

.timelog-grid-gps-item:last-child {
    border-right: 0px;
}

.timelogs-custom-button-transparent {
    min-width: 120px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #2550AC !important;
    line-height: 38px;
    border-radius: 10px;
    padding: 0 16px !important;
    border: 1px solid #2550AC !important;
}

.timelog-custom-table-label {
    margin-top: -10px;
    font-size: 14px;
}

/* styles for timelog array starts*/
.custom-timelog-array th {
    font-size: 14px !important;
    padding: 0.5rem !important;
    border-bottom: 0px !important;
    color: #1B1B1B !important;
    font-weight: 600 !important;
}

.custom-timelog-array td {
    font-size: 14px !important;
    padding: 0.5rem !important;
    border-bottom: 0px !important;
    color: #1B1B1B !important;
    font-weight: 400 !important;
    line-height: 25px;
    text-align: center;
}

.timelog_array_details {
    max-width: 80px;
    word-break: break-all;
}

.timelog_array_details_sign {
    max-width: 130px;
    word-break: break-all;
}

.timelogArraySign {
    padding-bottom: 0.1rem;
    width: 150px !important;
    height: 50 !important;
}

.timelogArrayImage {
    padding-bottom: 0.1rem;
    width: 50px !important;
    height: 50px !important;
}

.timelog-array-table-img {
    width: 50px;
    height: 50px;
    margin: 0 auto;
    border-radius: 12px;
    position: relative;
}

/* styles for timelog array ends*/
.timelog-final-time-card-modal {
    min-width: 115px;
    border-radius: 10px;
    text-align: center;
}

.timelog-final-time-card {
    min-width: 115px;
    background: #F9FAF5;
    border-radius: 10px;
    text-align: center;
    padding: 0.4rem;
}

.timelog-final-time-card-total {
    width: 90px !important;
    background-color: #F9FAF5 !important;
    border: 2px solid rgba(176, 190, 219, 0.67) !important;
    border-radius: 10px;
    text-align: center;
    padding: 0.4rem;
}

.borderworklog {
    border: 1px solid rgba(176, 190, 219, 0.67) !important;
}

.borderworklog:hover {
    border: 2px solid #2550AC !important;
}

.gps-info-label {
    color: #2550AC;
    font-weight: 600;
    font-size: 16px;
}

.timelog-right-fade-modal-container {
    position: fixed;
    bottom: 5px;
    min-width: 90%;
}

.timelog-modal-accept-buttons {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
}

.timelog-modal-accept-buttons button {
    width: auto !important;
    margin-right: 10px
}

.timelog-final-time-card-total:hover {
    border: 2px solid #2550AC !important;
}

.customize-datepicker {
    min-width: 270px;
}

@media screen and (max-width: 900px) {
    :root {
        touch-action: pan-x pan-y;
        height: 100%
    }

    .timelog-action-block-button {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
    }

    .timelog-grid-item-1 {
        display: flex;
        padding: 12px;
        border-right: 1px solid #BFD4E5 !important;
        border-top-right-radius: 10px !important;
        border-bottom-right-radius: 10px !important;
        margin-bottom: 1.5rem !important;
    }

    .timelog-grid-item-2 {
        display: flex;
        padding: 12px;
        border-top-left-radius: 10px !important;
        border-bottom-left-radius: 10px !important;
        margin-bottom: 1.5rem !important;
    }

    .timelog-grid-gps-item {
        border-right: 0px;
        border-bottom: 1px solid #BFD4E5;
        padding: 12px;
    }

    .timelog-grid-gps-item:last-child {
        border-bottom: 0px;
    }

    .timelog-grid-overtime-item {
        margin-bottom: 1.5rem;
    }

    .timelog-array-custom-margin {
        margin-top: 20px;
    }

    .timelogs-custom-button {
        width: 100%;
    }

    .timelogs-custom-button-transparent {
        width: 100%;
    }

    .customize-datepicker {
        display: flex;
        justify-content: center;
        min-width: 270px;
    }

    .timelog-save-button {
        flex-direction: column;
    }

    .timelog-save-button button {
        margin-top: 20px;
    }

    .btn-dynamic-lg {
        width: 100% !important;
    }

    .primary-page__title {
        margin-left: 10px !important;
    }

    .timelog-modal-accept-buttons {
        flex-direction: column !important;
    }

    .timelog-modal-accept-buttons button {
        margin-top: 10px;
    }
}

.timelogs-text-shrading {
    width: 75%;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}

.table>tbody>tr>td>.timelog-date {
    white-space: inherit !important;
}

@media (width <=767px) {
    .timelog-grid-gps-card {
        display: flex;
        flex-direction: column;
    }
}

@media screen and (min-width: 768px) {
    .timelog-grid-gps-card {
        display: flex;
        flex-direction: row;
        padding: 0 12px 0;
    }
}

.height-50 {
    height: 50px;
}