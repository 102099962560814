/* .landingPageWrapp .testiCol {
    background-color: var(--white);
    padding: 40px;
    border-radius: 40px 40px 40px 20px;
    box-shadow: 0px 33px 61px #0B0D3914;
    position: relative;
    margin: 40px 0 80px 0;
    z-index: 1;
}
.landingPageWrapp .testiColInfo h4 {
    font-size: 16px;
    color: #0B0D39;
    margin: 0;
}
.landingPageWrapp .testiColInfo p {
    font-size: 20px;
    color: rgba(11,13,57,0.6);
    line-height: 32px;
    margin: 24px 0 0 0;
}
.landingPageWrapp .testiColUser {
    display: flex;
    align-items: center;
    margin-top: 24px;
}
.landingPageWrapp .testiColUser span {
    display: inline-block;
    min-width: 54px;
    margin-right: 24px;
}
.landingPageWrapp .testiColUser span img {
    width: 54px;
    height: 54px;
    border-radius: 54px;
}
.landingPageWrapp .testiColUser h5 {
    font-size: 14px;
    color: #0B0D39;
    margin: 0 0 6px 0;
}
.landingPageWrapp .testiColUser p {
    font-size: 14px;
    color: rgba(11,13,57,0.5);
    line-height: 20px;
    margin: 0;
} */
/* 
@media (max-width:1199px) {
    .landingPageWrapp .testiCol {
        padding: 32px;
        border-radius: 24px;
        box-shadow: 0px 24px 61px #0b0d3914;
        margin: 0 0 50px 0;
    }
}

@media (max-width:1199px) {
    .landingPageWrapp .testiCol {
        padding: 24px;
        border-radius: 20px;
        box-shadow: 0px 16px 40px #0b0d3914;
        margin: 0 0 40px 0;
    }
    .landingPageWrapp .testiColInfo p {
        font-size: 17px;
        line-height: 30px;
        margin: 24px 0 0 0;
    }
} */

.landingPageWrapp .testimonial-client-card{
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    box-shadow: 0px 33px 61px #0B0D3914;
    background-color: var(--white);
    padding: 20px;
    border-radius: 30px 10px 30px 0px;
    margin: 10px 0 40px 0;
    z-index: 1;
}

.landingPageWrapp .testimonial-client-card img{
    min-width: 128px;
    max-width: 150px;
    height: 80px;
    -webkit-filter: grayscale(100%); /* Safari 6.0 - 9.0 */
    filter: grayscale(100%);
}