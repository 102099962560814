.submit-container{
    >.error-container{
        color: red;
        font-size: 18px;
        font-weight: 600;
        display: flex;
        justify-content: center;
        padding: 10px;
    }
}

.otp-container{
    >form{
        display: flex;
        flex-direction: column;
        align-items: center;
        >.otp-input-container{
            display: flex;
            justify-content: center;
            flex-wrap: wrap;
            >input{
                width: 50px;
                height: 50px;
                margin-right: 10px;
                margin-top: 10px;
                font-size: 25px;
                text-align: center;
                border-radius: 15px;
                border: 2px solid black;
                font-weight: 700;
                &:focus{
                    border: 2px solid #2550AC; 
                    box-shadow: 0 0 5px 5px #b7c9ed;
                }
            }
        }
    }
}

@media screen and (max-width: 576px){
    .otp-container{
        >form{
            >.otp-input-container{  
                >input{
                    width: 30px;
                    height: 30px;
                    margin-right: 5px;
                    margin-top: 5px;
                    font-size: 15px;
                    text-align: center;
                    border-radius: 10px;
                    border: 2px solid black;
                    font-weight: 700;
                    &:focus{
                        border: 2px solid #2550AC; 
                        box-shadow: 0 0 5px 5px #b7c9ed;
                    }
                }
            }
        }
    }
}
