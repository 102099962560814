.landingPageWrapp .mainHeading {
	margin-bottom: 60px;
}
.landingPageWrapp .mainHeading h3 {
    font-size: 54px;
    color: var(--secondary);
    font-weight: 700;
    line-height: 70px;
    margin: 0;
}
.landingPageWrapp .mainHeading h3 span {
    color: var(--primary);
}
.landingPageWrapp .mainHeading p {
    color: var(--secondary);
    line-height: 32px;
    margin-top: 30px;
}
.landingPageWrapp .mainHeading.white h3,
.landingPageWrapp .mainHeading.white p {
    color: #fff;
}
.landingPageWrapp .mainHeading.noPara p {
    display: none;
}
.landingPageWrapp .mainHeading.text-center p {
    max-width: 800px;
    margin: auto;
}
.landingPageWrapp .mainHeading.medium {
    margin-bottom: 45px;
}
.landingPageWrapp .mainHeading.medium h3 {
    font-size: 44px;
    line-height: 60px;
    margin-bottom: 0;
}
.landingPageWrapp .mainHeading.medium p {
    margin-top: 20px;
}
.landingPageWrapp .mainHeading.small {
    margin-bottom: 30px;
}
.landingPageWrapp .mainHeading.small h3 {
    font-size: 32px;
    line-height: 44px;
}
.landingPageWrapp .mainHeading.small p {
    margin-top: 10px;
}

@media (max-width:1399px) {
    .landingPageWrapp .mainHeading {
        margin-bottom: 50px;
    }
    .landingPageWrapp .mainHeading h3 {
        font-size: 50px;
        line-height: 64px;
        margin: 0 0 24px 0;
    }
    .landingPageWrapp .mainHeading.medium {
        margin-bottom: 40px;
    }
    .landingPageWrapp .mainHeading.medium h3 {
        font-size: 40px;
        line-height: 56px;
    }
    .landingPageWrapp .mainHeading.small h3 {
        font-size: 30px;
        line-height: 40px;
    }
}

@media (max-width:1199px) {
    .landingPageWrapp .mainHeading {
        margin-bottom: 40px;
    }
    .landingPageWrapp .mainHeading h3 {
        font-size: 40px;
        line-height: 56px;
        margin: 0 0 16px 0;
    }
    .landingPageWrapp .mainHeading.medium {
        margin-bottom: 30px;
    }
    .landingPageWrapp .mainHeading.medium h3 {
        font-size: 32px;
        line-height: 44px;
    }
    .landingPageWrapp .mainHeading.small h3 {
        font-size: 28px;
        line-height: 40px;
    }
}

@media (max-width:767px) {
    .landingPageWrapp .mainHeading h3 {
        font-size: 32px;
        line-height: 44px;
    }
    .landingPageWrapp .mainHeading.medium h3 {
        font-size: 30px;
    }
    .landingPageWrapp .pageBannerBlock .mainHeading {
        margin-bottom: 0;
    }
}