.landingPageWrapp .feaCol {
    background: var(--white);
    padding: 24px;
    text-align: center;
    border-radius: 30px;
    transition: all 0.3s ease 0s;
    border: 1px solid var(--white);
    height: 100%;
}
.landingPageWrapp .feaCol.heightEqual {
    min-height: 320px;
}
.landingPageWrapp .feaCol.grey {
    border-color: rgba(112,112,112,0.4);
}
.landingPageWrapp .feaCol:hover {
    border: 1px solid var(--primary);
}
.landingPageWrapp .feaColIcon {
    margin-bottom: 24px;
}
.landingPageWrapp .feaColIcon i {
    display: inline-block;
    width: 89px;
    line-height: 89px;
    border-radius: 30px;
}
.landingPageWrapp .feaColIcon.blue i {
    background: transparent linear-gradient(313deg, #40C8FF 0%, #BDECFF 100%) 0% 0% no-repeat padding-box;
}
.landingPageWrapp .feaColIcon.orange i {
    background: transparent linear-gradient(313deg, #FF9A73 0%, #FFC3AB 100%) 0% 0% no-repeat padding-box;
}
.landingPageWrapp .feaColIcon.green i {
    background: transparent linear-gradient(313deg, #33DCDC 0%, #9EEEEE 100%) 0% 0% no-repeat padding-box;
}
.landingPageWrapp .feaColIcon.pink i {
    background: transparent linear-gradient(313deg, #FF4D8D 0%, #FFBDD5 100%) 0% 0% no-repeat padding-box;
}
.landingPageWrapp .feaColInfo h4 {
    font-size: 24px;
    font-family: 'Medium' !important;
    line-height: 34px;
}
.landingPageWrapp .feaColInfo p {
    color: #828282;
    line-height: 28px;
    margin: 0;
}
.landingPageWrapp .feaColInfo a {
    font-size: 15px;
    color: var(--primary);
    font-family: 'Bold' !important;
    transition: all 0.3s ease 0s;
    display: inline-block;
    margin-top: 16px;
}
.landingPageWrapp .feaColInfo span {
    display: inline-block;
    transition: all 0.3s ease 0s;
    margin-right: 6px;
}
.landingPageWrapp .feaColInfo a:hover {
    color: var(--primary);
}
.landingPageWrapp .feaColInfo a:hover span {
    margin-right: 12px;
}
.landingPageWrapp .noLink .feaColInfo a {
    display: none;
}

@media (max-width:1199px) {
    .landingPageWrapp .feaCol {
        padding: 24px;
        border-radius: 24px;
    }
}