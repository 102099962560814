.table {
  margin-top: 1.5rem;

  >thead>tr>th,
  >tbody>tr>th,
  >tfoot>tr>th,
  >thead>tr>td,
  >tbody>tr>td,
  >tfoot>tr>td {
    padding: 0.5em;
    vertical-align: middle;
    font-size: $font-size-base - 2;
    font-weight: $font-weight-bold !important;
    background-color: transparent;

    >div {
      &.form-checkbox {
        >input {
          &.checkbox {
            display: block;
            padding: 0px 0px;
            background-color: #D9D9D9;
            border-radius: 6px;
            cursor: pointer;
            margin: 0;
            &.icons {
              left: 5px;
            }
          }
        }
      }
    }
  }

  >tbody>div{
    font-size: $font-size-base - 2;
    font-weight: $font-weight-bold !important;
  }

  >tbody>tr>td {
    &.noData {
      font-size: $font-size-medium;
      font-weight: $font-weight-bold;
      color: $color-dark-gray;
    }
  }

  >thead>tr>th {
    text-transform: capitalize;
    color: $table-header;
    font-weight: $font-weight-bold;
    white-space: nowrap;
    text-align: center;

    &.__flex_header {
      display: flex;
    }

    &.__titile_padding_left {
      padding-left: 6%;
    }

    >span {
      white-space: nowrap;
      display: block;
      width: 90%;
      overflow: hidden;
      text-overflow: ellipsis;
      font-weight: $font-weight-bold !important;
    }

    >img {
      width: 16px;
      height: 20px;
    }
  }

  >tbody>tr>td {
    border-bottom-width: 0px !important;
    text-align: center;
    margin: auto;

    .description {      
      text-align: justify;
      line-height: 1.5;
    }

    >span {
      white-space: nowrap;
      display: block;
      width: 100%;
      overflow: hidden;
      text-overflow: ellipsis;
      font-weight: $font-weight-bold !important;
    }
  }

  >tbody>tr:nth-of-type(even) {
    background-color: $table-tr-strip-even;
    +div{
      background-color: $table-tr-strip-even;
    }
  }

  >.dashboard-schedule-table-body>tr {
    background-color: #FAFAFA !important;
  }

  >.contractform-table-body>tr {
    background-color: #FAFAFA !important;

    &:hover {
      background-color: #FAFAFA !important;
    }
  }

  .td-actions .btn {
    @include opacity(0.36);

    &.btn-xs {
      padding-left: 3px;
      padding-right: 3px;
    }
  }

  .td-actions {
    min-width: 90px;
  }

  >tbody>tr {
    position: relative;
    &:hover {
      .td-actions .btn {
        @include opacity(1);
      }
    }
  }
}

.table-bigboy {
  >thead>tr>th {
    font-size: $font-size-h6;
    text-transform: uppercase;
    color: $dark-gray;
    font-weight: $font-weight-normal;
  }

  >tbody>tr>td {
    font-size: $font-size-base;

    b {
      display: block;
      margin-bottom: 5px;
    }
  }

  .td-name {
    font-weight: $font-weight-light;
    font-size: $font-size-h5;
  }

  .td-name {
    min-width: 240px;
  }

  .td-number {
    text-align: right;
    min-width: 110px;

    small {
      margin-right: 3px;
    }
  }

  .td-actions {
    width: 60px;
    min-width: auto;

    .btn {
      float: right;
    }
  }

  .img-container {
    width: 180px;
    height: 120px;
    overflow: hidden;
    display: block;

    border-radius: $border-radius-large;

    img {
      width: 100%;
    }
  }
}

.timelog-array__right table {
  border-collapse: separate;
  border-spacing: 0 10px;
}

.timelog-array__right tbody tr {
  background-color: #D9D9D9;
}

.timelog-array__right td:first-child {
  border-top-left-radius: 10px;
}

.timelog-array__right td:last-child {
  border-top-right-radius: 10px;
}

.timelog-array__right td:first-child {
  border-bottom-left-radius: 10px;
}

.timelog-array__right td:last-child {
  border-bottom-right-radius: 10px;
}

.table-container-header {
  font-size: 18px;
  color: #2550AC;
  font-weight: 600;
}

.tbody-row{
  container-type: inline-size;
}

@container (width > 1257px ){
  .tbody-row .checkbox__table_list_container > label::after {
    top:20px
  }
}


@include table-row-variant("success", $light-green);
@include table-row-variant("info", $light-azure);
@include table-row-variant("warning", $light-orange);
@include table-row-variant("danger", $light-red);