.header1-text {
    font-size: 25px;
    color: #90CDF9;
    font-weight: 500;
}

.header2-text {
    font-size: 14px;
    font-weight: 500;
}

.add-more-button {
    display: flex;
    justify-content: center;
    align-items: center;
    opacity: 0;
    z-index: 999999;
}

.add-more-button:hover {
    opacity: 1;

}

.add-more-button div {
    padding-top: 2px;
    text-align: center;
}

.header3-text {
    font-size: 12px;
    font-weight: 500;
    text-align: center;
}

.base-text {
    font-size: 12px;
}

.help-text {
    font-size: 12px;
    color: #999;
}

.disabled-text {
    font-size: 12px;
    color: #ccc;
}

.scheduler {
    margin: 10px auto;
    border-spacing: 0;
}

.scheduler td {
    padding: 0px;
}

.resource-view {
    border: 1px solid #e9e9e9;
    overflow: hidden;
}

.scheduler-view {
    border: 1px solid #e9e9e9;
    margin: 0 0 0 -1px;
    padding: 0;
}

.scheduler-content {
    position: relative;
    z-index: 2;
}

.scheduler-bg {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1;
}

table.resource-table,
table.scheduler-bg-table,
table.scheduler-table {
    width: 100%;
    margin: 0;
    padding: 0;
    border-spacing: 0;
    text-align: center;
}

.timeline-event {
    position: absolute;
    text-decoration: none;
}

.timeline-event span {
    color: black;
    font-size: 15px;
    font-weight: 600;
}

.day-event {
    position: relative;
    display: inline-block;
    margin: 0px 5px;
}

.day-event-container {
    text-align: left;
    padding: 5px 5px 0 5px;
}

.round-all {
    border-radius: 14px;
}

.round-head {
    border-radius: 14px 0px 0px 14px;
}

.round-tail {
    border-radius: 0px 14px 14px 0px;
}

.round-none {
    border-radius: 0px;
}

.event-container {
    position: relative;

}

.event-item {
    margin: 1px 0;
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    color: #fff;
    border-top: 1px solid white;
    padding-right: 20px !important;
}

.overflow-text {
    overflow: hidden;
    text-overflow: ellipsis;
}

.status-dot {
    width: 14px;
    height: 14px;
    border-radius: 7px;
}

.ant-radio-button-wrapper-checked {
    background-color: #108EE9;
    color: #FFFFFF;
}

.icon-nav:hover {
    color: #1E90FF !important;
    box-shadow: 0 0 0px !important;
    cursor: pointer;
}

.add-more-popover-overlay {
    position: absolute;
    z-index: 5;
    border: 1px solid #e5e5e5;
    background-color: #fff;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.25);
    padding: 10px;
}

.popover-calendar {
    width: 300px;
}

.popover-calendar .ant-calendar {
    box-shadow: 0 1px 6px rgba(0, 0, 0, 0) !important;
}

.event-resizer {
    position: absolute;
    z-index: 4;
    display: block;
    width: 7px;
    top: -1px;
    bottom: -1px;
}

.event-start-resizer {
    cursor: w-resize;
    left: -1px;
}

.event-end-resizer {
    cursor: e-resize;
    right: -1px;
}

.selected-area {
    position: absolute;
}

.slot-cell {
    text-align: center;
}

.slot-text,
.slot-text-price {
    display: inline-block;
    font-size: 14px;
    line-height: 15.5px;
    color: #000 !important;
    font-weight: 600;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-left: 10px;
}

.slot-text-price {
    font-weight: 600;
    font-size: 12px;
    color: #5e5c5c !important;
}

.slot-text,
.slot-text-time {
    font-size: 14px;
    line-height: 15.5px;
    color: #000 !important;
    font-weight: 600;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-left: 5px;
    width: 110px;
}

.slot-text-time {
    font-weight: 600;
    font-size: 12px;
    color: #5e5c5c !important;
}

.RBS-Scheduler-config {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-right: 1em;
    margin-bottom: 1em;
}

.RBS-Scheduler-config .scheduleCaret {
    border-top: 0.4em solid;
    border-right: 0.4em solid transparent;
    border-left: 0.4em solid transparent;
    color: #9BA7BF !important;
    height: 0;
    width: 0;
}

.RBS-Scheduler-config .rotate-270 {
    -webkit-transform: rotate(270deg);
    -moz-transform: rotate(270deg);
    -ms-transform: rotate(270deg);
    -o-transform: rotate(270deg);
    transform: rotate(270deg);
}

.RBS-Scheduler-config .rotate-90 {
    -webkit-transform: rotate(90deg);
    -moz-transform: rotate(90deg);
    -ms-transform: rotate(90deg);
    -o-transform: rotate(90deg);
    transform: rotate(90deg);
}


.RBS-Scheduler-config>button {
    margin-right: 10px;
    margin-bottom: 10px;
}

.slot-text-pending {
    display: inline-block;
    padding-left: 4px;
    padding-right: 4px;
    font-size: 16px;
    line-height: 15.5px;
    font-weight: 600;
}


.slot-text-p {
    display: inline-block;
    padding-left: 4px;
    padding-right: 4px;
    font-size: 13px;
    line-height: 18px;
    color: #000 !important;
    font-family: Roboto;
}

.slot-text-count {
    text-transform: uppercase;
    font-weight: 900;
    font-size: 12px;
    color: #4b5155;
    margin: 0 5px;
    width: 18px;
    height: 18px;
    background-color: #dfdfdf;
    border-radius: 50%;
    align-items: center;
    display: flex;
    justify-content: center;
}

/* New schedule plan css start */
.mb_18 {
    margin-bottom: 12px;
}

.circle {
    display: inline-block;
    font-size: 10px;
    width: 2.5em;
    height: 2.5em;
    line-height: 2.5em;
    text-align: center;
    border-radius: 50%;
    background-color: #ff8c00;
    margin-right: 1em;
    color: #fff;
}

.circle_new {
    display: inline-block;
    font-size: 10px;
    width: 2.5em;
    height: 2.5em;
    line-height: 2.5em;
    text-align: center;
    border-radius: 50%;
    background-color: #447df7;
    margin-right: 1em;
    color: #fff;
}

.shiftDetail {
    font-family: Roboto;
    font-size: 13px;
    line-height: 17px;
    color: #989898;
    display: block;
    font-weight: 400 !important;
    margin-top: 3px;
}

.shiftDetail_pending {
    font-family: Roboto;
    font-size: 13px;
    line-height: 17px;
    display: block;
    font-weight: 400 !important;
    margin-top: 3px;
}


.flexItems {
    display: flex;
    padding: 0px 5px;
}

.fontStyles {
    font-family: Roboto;
    font-size: 15px;
    font-weight: normal;
}

.scheduler>tbody tr td .scheduler-view>div .scheduler-content .scheduler-content-table tbody tr .event-container .timeline-event .test-item {
    border-radius: 0px !important;
}

.schedule_shift_event_template {
    display: flex;
    flex-direction: column;
    justify-content: center;
    background: linear-gradient(0deg, #D5DDF2, #D5DDF2),
        linear-gradient(0deg, #D6F0D5, #D6F0D5);
    border-left: 5px solid #7D93CE;
    white-space: nowrap;
    overflow: hidden;
    line-height: 1.4;
    cursor: pointer;
    
    &.read_only{
        opacity: 0.7;
    }

    &.late,
    &.rejected {
        background: #F6EDE4 !important;
        border-left: 5px solid #FF4747 !important;
    }

    &.on_time,
    &.approved {
        background: linear-gradient(0deg, #D6F0D5, #D6F0D5),
            linear-gradient(0deg, #D9F2D5, #D9F2D5) !important;
        border-left: 5px solid #8FCF84 !important;
    }

    &.no_show {
        background: #F0F2F6 !important;
        border-left: 5px solid #adadad !important;
    }

    &.pending {
        background: #F2F1D5;
        border-left: 5px solid #D4D293;
    }

    >span {
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;

        &.shift_time {
            display: flex;
            align-items: center;
            margin-left: 4px;

            >span {
                font-size: 10px;
                display: block;
                width: 70%;
                text-overflow: ellipsis;
                white-space: nowrap;
                overflow: hidden;
            }
        }

        &.shift_details {
            margin-left: 6px;
            display: block;
            width: 80%;
            font-size: 10px;

            &.shift_resource_name {
                font-size: 12px;
                color: #2550AC;
            }

            &.has_bonus {
                margin-left: 2px;
            }
        }
    }
}

.week_block {
    color: #2550AC;
    font-size: 14px;
    font-weight: 600;
    margin-right: 10px;
}

.set_dropdown {
    border: '1px solid #e8e8e8 !important'
}

.custom_dropdown .open:hover {
    background-color: white;
}

.custom_dropdown .open>.dropdown-toggle.btn-default:focus,
.custom_dropdown .open>.dropdown-toggle.btn-default:hover {
    background-color: #fff;
}

.custom_dropdown .css-2b097c-container .css-2613qy-menu {
    z-index: 1000000;
}

.set_dropdown .caret {
    border-top-color: #000 !important;
    margin-left: 15px !important;
}

.set_threeDots {
    width: 19px;
    height: 19px;
    position: absolute;
    top: 0px;
    right: 0px;
    border-top-right-radius: 5px;

    >i {
        cursor: pointer;
    }
}

.set_checkbox {
    --top-value: 28px;
    position: absolute;
    top: var(--top-value);
    right: 4px;

    &.extra_top_10px {
        top: calc(var(--top-value) + 10px);
    }

    >div>input {
        width: 12px !important;
        height: 12px !important;
        cursor: pointer;
    }
}

.set_boxIcon {
    color: #b4b4b4 !important;
    margin-right: 10.5px;
    padding: 0px 5px;
    z-index: 1000;
}

.openBox {
    min-width: 132px;
    min-height: 75px;
    background-color: #fff;
    position: absolute;
    display: flex;
    flex-direction: column;
    right: -29px;
    top: 20px;
    z-index: 99999999;
    border-radius: 16px;
    padding: .9em;
    box-shadow: 0px 4px 38px rgba(0, 0, 0, 0.07);

    &.openBoxMember {
        min-height: 48px;
    }
}

.openBox .openBoxOptionTxt {
    font-size: 12px;
    display: flex;
    justify-content: start;
    align-items: center;
}

.openBox .openBoxOptionTxt>img {
    margin-right: 1em;
    width: 12px;
}

.boxHeading {
    color: #333;
    font-family: Roboto;
    font-size: 12px;
}

.highlighted_color {
    background-color: #447df7;
    color: #fff;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.inherit_color {
    background-color: inherit;
}

.set_viewStyle {
    font-size: 16px;
}

/* overriding radio button css */
.ant-radio-button-wrapper {
    border: none !important;
    width: 100%;
}

.ant-radio-button-wrapper:hover {
    color: #707070 !important;
    background-color: #e8e8e8 !important;
}

.ant-radio-button-wrapper-checked {
    color: #707070 !important;
    border: none !important;
}

.setCustomTab .nav-tabs a {
    color: #333;
    font-size: 15px;
    font-family: 'Roboto', sans-serif;

}

.setCustomTab .nav-tabs .active a {
    border: none !important;
    border-bottom: 1px solid #447df7 !important;
    color: #447df7 !important;

}

/* Below property set to avoid jump of element */
.setCustomTab .nav-tabs a {
    border: 1px solid transparent !important;

}

.setCustomTab .nav-tabs .active a:hover {
    border-bottom: 1px solid #447df7 !important;
    color: #447df7 !important;
    pointer-events: none !important;

}

.set_ModalHeader .close {

    font-size: 3.2em;
    line-height: 27px;
    color: #b4b4b4;
}

.p_0 {
    padding: 0px !important;
}

.p_5 {
    padding: 5px;

}

.p_10 {
    padding: 10px !important;
}

.schedule_non_shift_event_template {
    --parent-height: 2em;
    height: var(--parent-height);
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    >span {
        display: flex;
        font-size: 10px;
    }

    &.absence_vacation {
        background: #7D93CE;
    }

    &.available {
        background-color: #8FCF84;
    }

    &.not_available {
        background-color: darkorange;
    }

    &.extra_1em {
        height: calc(var(--parent-height) + 1em);
    }
}

.change_bg_available {
    background-color: #8FCF84 !important;
    border: none !important;
    height: 2em !important;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
}

.change_bg_notAvailable {
    background-color: darkorange !important;
    border: none !important;
    color: #fff !important;
    height: 2em !important;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
}

.set_today {
    background-color: #447df7 !important;
    color: #fff !important;
    border: #fff !important;
}

.unset_today {
    background-color: inherit;
    border: inherit;
    color: inherit;
}

/* bulk shift design*/
.custom-margin-left-35 {
    margin-left: 35px;
}

.custom-bulk-shift-card {
    border: 1px solid #91969E;
    border-radius: 50px;
    align-items: center;
    min-height: 38px;
    display: flex;
    margin: .2rem;
    max-width: fit-content;
}

/* New schedule plan css end */
.text-right {
    text-align: right;
}

.text-decoration-none {
    text-decoration: none !important;
}

/* role view UI Updates*/

.header-card {
    margin: 0px;
    padding: 0px;
    container-type: inline-size;

    .__day_publish_container {
        cursor: pointer;
        max-width: 30px;

        >div>i {
            border: 1px solid #C6CEDD;
            border-radius: 5px;
            padding: 2px;

            &._day_published {
                color: green;
            }

            &._day_unpublished {
                color: red;

            }
        }
    }

    .__dates_container {
        flex-direction: row;
        justify-content: center;

        .__day {
            font-size: 14px;
            font-weight: 600;
            margin-right: 10px;
        }

        .__date {
            font-size: 14px;
            font-weight: 600;
        }

    }

    .__date_totals_container {
        padding-right: 15px;

        ._day_budget_headers {
            margin-right: 5px;
            color: #5e5c5c;
            display: block;
            max-width: 30%;
        }

        .__day_buget_details {
            color: #2550AC;
            text-decoration: underline;
            cursor: pointer;
            max-width: 60%;
        }

        .__day_cost {
            color: #000;
            max-width: 40%;
        }

        .__day_buget_percent {
            color: #35C229;
            max-width: 30%;
        }

        ._day_budget_headers,
        .__day_buget_details,
        .__day_cost,
        .__day_buget_percent {
            font-size: 8px;
            font-weight: 600;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
        }
    }
}

.plr-0-5 {
    padding: 0px 5px;
}

@container (width < 130px) {
    .header-card {
        .__day_publish_container {
            >div>i {
                font-size: 8px;
                max-width: 15px;
            }
        }

        .__dates_container {
            .__day {
                font-size: 10px;
                font-weight: 600;
            }

            .__date {
                font-size: 10px;
                font-weight: 600;
            }
        }

        .__date_totals_container {

            .__day_cost,
            .__day_buget_percent {
                font-size: 6px;
            }

            .__day_buget_details,
            ._day_budget_headers {
                font-size: 8px;
            }
        }
    }
}

.smaller_checkbox {
    width: 12px;
}