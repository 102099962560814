.dashboard-project-panel {
    background: white;
    padding: 10px;
}
.totals.project .label{
    background: #744098;
}
.totals.hours .label{
    background: #FF1E50;
}
.totals.project .value {
    color: #744098;
}
.totals.hours .value {
    color: #FF1E50;
}
.totals .label {
    color: white;
    width: 250px;
    min-width: 250px;
    display: inline-block;
    line-height: 25px;
    height:25px;
    margin-bottom: 10px;
    margin-right: 20px;
}

.totals .value {
    font-weight: bold;
    display: inline-block;
}

.dashboard-title {
    font-weight: 600;
    color: #2550AC;
    font-size: 20px;
    font-family: 'Exo 2';
}

.dashboard-greeting {
    color: #9398AE;
    font-family: 'Exo 2';
    font-weight: 600;
    font-size: 14px;
}

.dashboard-progressBar-header-text{
    font-size: 0.9em;
    font-weight: 600;
    font-family: 'Exo 2';
}

.dashboard-progressBar-value{
    font-size: 1.5em;
    font-weight: 700;
    font-family: 'Exo 2';
}

.dashboard-progressBar-value-color-51459E{
    color: #51459E;
}
.dashboard-progressBar-value-color-EE2222{
    color: #EE2222;
}
.dashboard-progressBar-value-color-5CCCFC{
    color: #5CCCFC;
}
.dashboard-progressBar-value-color-ffa534{
    color: #ffa534;
}
.dashboard-container .dashboard-nav-link{
    font-size: 20px;
    font-weight: 600;
    color: #2550AC;
    /* text-decoration: none; */
}

.dashboard-container .dashboard-nav-link:hover{
    text-decoration: #2550AC underline;
}

.dashboard-container a{
    font-weight: 600;
}

.dashboard-container .card{
    /* border: none; */
}

.dashboard-container .card .card-body{
    padding: 4px !important;
}

/* Dashboard Tabs styling */

.dashboard-tabs-container{
    overflow-x: auto;
}

.dashboard-tabs-container .nav-tabs{
    /* overflow-x: hidden; */
    overflow-y: hidden;
    flex-wrap: nowrap;
}

.dashboard-tabs-container .nav-tabs{
    -ms-overflow-style: none;  /* Internet Explorer 10+ */
    scrollbar-width: none;  /* Firefox */
}
.dashboard-tabs-container .nav-tabs::-webkit-scrollbar { 
    display: none;  /* Safari and Chrome */
}

.dashboard-tabs-container .tab-content{
    max-height: 400px;
    overflow-y: auto;
}
.nav-item{
    /* border: 2px solid red; */
}

.dashboard-tabs-container .nav-item button:focus{
    outline: none !important;
    box-shadow: none !important;
}

.dashboard-tabs-container .nav-tabs .nav-link:hover{
    border: transparent;
    /* padding-bottom: 0; */
}

.dashboard-tabs-container .nav-item .nav-link{
    font-size: 14px;
    color: #1E408A;
    font-weight: 600;
    font-family: 'Exo 2';
    margin-bottom: 0px;
    border: transparent;
}



.dashboard-tabs-container .nav-tabs .nav-link.active{
    background-color: inherit !important;
    border: none;
    border-bottom: 2px solid #2550AC;
}



/* Dashboard Tabs styling End */

.table-dashboard-schedule{
    border-collapse: separate;
    border-spacing: 0 0.5em;
}

.table-dashboard-schedule td:first-child {
    /* padding-left:20px; */
    padding-right:0;
    padding-top:15px;
    padding-bottom:15px;
    border-bottom-left-radius: 10px;
    border-top-left-radius: 10px;
  }
  .table-dashboard-schedule tr td:last-child {
    border-bottom-right-radius: 10px;
    border-top-right-radius: 10px;
  }

.deviation{
    /* border-bottom: 1px solid #D0D8EA; */
}  