@media screen and (min-width: 1050px) {
  .bulk-shift-modal {
    /* width: 1000px !important; */
    width: 800px !important;
  }
}


/* New shift-form css */
.waiting_badge {
  background-color: #2550AC !important;
  margin: 0 0 0 14px;
  padding: .9em !important;
  height: auto !important;
  width: 155px;
  border-radius: 50px;
  font-size: 14px;

  &.pending {
    background-color: darkorange !important;
  }
}

/* .shift-chekbox-style {
  margin-top: 1.4em;
} */
/* bulk shift-form css*/
.waiting_badge_bulk {
  display: flex;
  align-items: center;
  background-color: #FFC800;
  justify-content: center;
  border-radius: 50px;
  font-size: 12px;
  font-weight: 600;
  color: white;
  width: 155px;
  height: 40px;
}

.fs15 {
  font-size: 15px;
  line-height: 18px;
}

.fs10 {
  font-size: 10px;
  line-height: 13px;
}

/* Css for new calendar of month */

/* .customize_calendar .rbc-toolbar{
  display: none;
} */

.customize_calendar .rbc-event {
  /* background-color: #f9f9f9; */
  margin: 0 0px;
  width: inherit;
  background-color: #fff;
  border-radius: none;
}

.customize_calendar .rbc-event .rbc-event-content {
  font-size: 11px;
  /* font-family: Roboto; */
  background-color: #fff;
  color: #000;

}

.customize_calendar .vacation_absent_event {
  background-color: #447df7;
  color: #fff;
  padding-left: 2px;
}

/* .customize_calendar .selected_event{
  background-color: #447df7 !important;
} */
.customize_calendar .selected_event .rbc-event-content .event_content .none_Event {
  background-color: #447df7 !important;
  color: #fff !important;

}

.customize_calendar .selected_event .rbc-event-content .event_content .none_Event .none_circle {
  color: #fff !important;

}

.customize_calendar .selected_event .rbc-event-content .event_content .noshow_Event {
  background-color: #b4b4b4 !important;
  color: #fff !important;

}

.customize_calendar .selected_event .rbc-event-content .event_content .noshow_Event .noshow_circle {
  color: #fff !important;
}

.customize_calendar .selected_event .rbc-event-content .event_content .ontime_Event {
  background-color: #73B539 !important;
  color: #fff !important;

}

.customize_calendar .selected_event .rbc-event-content .event_content .ontime_Event .ontime_circle {
  color: #fff !important;

}

.customize_calendar .selected_event .rbc-event-content .event_content .late_Event {
  background-color: red !important;
  color: #fff !important;
}

.customize_calendar .selected_event .rbc-event-content .event_content .late_Event .late_circle {
  color: #fff !important;
}


.customize_calendar .rbc-month-view .rbc-month-row .rbc-row-bg .rbc-today {
  /* background: #eaf6ff; */
  /* background: #447df7; */
  border: 1px solid #447df7;
  background-color: #fff;
}

.customize_calendar .rbc-month-view .rbc-header {
  font-size: 15px;
  color: #333;
  font-weight: 600;
  text-align: center;
  font-family: Roboto;
  letter-spacing: 0.8px;
}

.customize_calendar .rbc-event:focus {
  outline: none;
}

.customize_calendar .rbc-event {
  margin-top: 2px;
}

/* Overlay styling */
.rbc-overlay .rbc-overlay-header {
  font-size: 15px;
  color: #333;
  font-family: Roboto;
}

.rbc-overlay .rbc-event {
  background-color: #f9f9f9;
  padding: 3px;
  margin-top: 2px;
  font-size: 11px;
  color: #fff;
  /* font-family: Roboto; */
}

.rbc-event:focus {
  outline: none !important;
}

.rbc-overlay .selected_event .rbc-event-content .event_content .none_Event {
  background-color: #447df7 !important;
  color: #fff !important;

}

.rbc-overlay .selected_event .rbc-event-content .event_content .none_Event .none_circle {
  color: #fff !important;

}

.rbc-overlay .selected_event .rbc-event-content .event_content .noshow_Event {
  background-color: #b4b4b4 !important;
  color: #fff !important;

}

.rbc-overlay .selected_event .rbc-event-content .event_content .noshow_Event .noshow_circle {
  color: #fff !important;

}

.rbc-overlay .selected_event .rbc-event-content .event_content .ontime_Event {
  background-color: #73B539 !important;
  color: #fff !important;

}

.rbc-overlay .selected_event .rbc-event-content .event_content .ontime_Event .ontime_circle {
  color: #fff !important;

}

.rbc-overlay .selected_event .rbc-event-content .event_content .late_Event {
  background-color: red !important;
  color: #fff !important;
}

.rbc-overlay .selected_event .rbc-event-content .event_content .late_Event .late_circle {
  color: #fff !important;
}

.rbc-overlay .vacation_absent_event {
  background-color: #447df7;
  color: #fff;
}


.customize_calendar .rbc-row .rbc-event-content {
  /* padding: 3px; */
  /* border-radius: 5px; */
}

/* .customize_calendar .rbc-calendar .rbc-toolbar span:nth-last-child(1){
  display: none;
} */

/* Customize toolbar */

.customize_toolbar .rbc-toolbar {
  padding: 0px;
  margin-bottom: 0px;
}

.customize_toolbar .rbc-toolbar .rbc-btn-group {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0px;
  margin-bottom: 1em;
}

.customize_toolbar .rbc-toolbar .rbc-btn-group:nth-of-type(1) {
  flex-grow: 1;
}

.customize_toolbar .rbc-toolbar .rbc-btn-group:nth-of-type(2) {
  flex-grow: 3;
}

.customize_toolbar .rbc-toolbar .rbc-btn-group:nth-of-type(3) {
  flex-grow: 1;
}

.customize_toolbar .rbc-toolbar .month_btn {
  font-weight: 600;
  font-size: 14px;
  background-color: #F9FAF5;
  border-radius: 10px;
  color: #000;
}

.customize_toolbar .rbc-toolbar .month_btn:hover {
  background-color: #F9FAF5 !important;
  color: #000 !important;
  text-decoration: underline;
}

.customize_toolbar .rbc-toolbar button {
  border: none;
}

.customize_toolbar .rbc-toolbar .rbc-toolbar-label {
  font-size: 15px;
}

.customize_toolbar .rbc-toolbar .rbc-btn-group button:hover {
  background-color: #fff;
  color: #b4b4b4;
}

.customize_toolbar .info_box {
  position: absolute;
  width: 150px;
  min-height: 100px;
  background-color: #fff;
  color: '#000';
  border: 1px solid #ddd;
  z-index: 10;
  /* left: -42px; */
  right: -25px;
  top: 37px;
  border-radius: 10px;
  padding: 9px;
  font-size: 14px;
  white-space: normal;
  font-family: Roboto;
  box-shadow: 0 0 6px #ddd;
}

.customize_toolbar .hide_info_box {
  display: none;
}

/* Customize toolbar end */

/* End css for new calendar of month */


/* End shift-form css */

/* Availability module css */
.availability-card {
  margin-top: 2em;
  border-radius: 4px;
  padding: 1em;
}

.workingDayCircle {
  display: block;
  height: 12px;
  width: 12px;
  border-radius: 50%;
  background: #49BA04;

  &.weekend {
    background: red;
  }
}

.availability-card .avlbty-info {
  display: flex;
}

.availability-card .avlbty-info>div {
  margin-right: 1em;
}

.availability-days {
  border-bottom: 1px solid #2550AC;
  display: flex;
  align-items: center;
  padding-bottom: 10px;
}

.availability-card div.availability-days.row-flex:nth-child(2) {
  border-top: 1px solid #2550AC !important;
  margin-top: 2em;

}

.availability-days .title {
  color: #000;
}

.availability-table .rt-thead .rt-resizable-header-content,
.availability-table .rt-thead .rt-tr {
  background-color: transparent !important;
  color: gray !important;
}

.availablility-status {
  margin: 10px 0px 10px 0px;
  padding: 0px 10px 0px 10px;
  border-radius: 20px;

  &.green_border {
    border: 2px solid lightgreen;
  }

  &.orange_border {
    border: 2px solid rgb(255 206 107)
  }

  &.red_border {
    border: 2px solid red;
  }

  &.ligth_red_border {
    border: 2px solid rgb(223, 76, 76);
  }
}

.availability-status-title {
  margin: 10px 0px 10px 0px
}

/* schedule management style */


.scheduleManagementTabs {
  border: none;

}

.scheduleManagementTabs>.nav-item>.nav-link {
  color: #2550AC;
  font-weight: 600;
  font-size: 16px;
  border-radius: 0;
  border-top: 1px solid #9EAFD2;
  border-bottom: 1px solid #9EAFD2;
}

.scheduleManagementTabs>.nav-item:first-child>.nav-link {
  border-left: 1px solid #9EAFD2;
  border-top-left-radius: 14px;
  border-bottom-left-radius: 14px;
}

.scheduleManagementTabs>.nav-item:last-child>.nav-link {
  border-right: 1px solid #9EAFD2;
  border-top-right-radius: 14px;
  border-bottom-right-radius: 14px;
}

.scheduleManagementTabs>.nav-item>.nav-link.active {
  background: #2550AC;
  color: #fff;


}

.scheduleManagementTabs>.nav-item>.nav-link.active {
  background: #2550AC;
  color: #fff;
}

/* .scheduleManagementTabs .nav-link:focus, .scheduleManagementTabs .nav-link:hover {
  isolation: unset;

} */
.scheduleModalBody {
  padding: 2em;
}

.scheduleModalHeader {
  position: relative;
}

.scheduleModalClosebtn {
  border: none;
  width: 33px;
  height: 33px;
  border-radius: 50%;
  font-weight: 600;
  background: #D9D9D9;
  position: absolute;
  top: 0;
  bottom: 0;
  margin: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

}

.scheduleModalClosebtn img {
  height: 12px;
  width: 12px;
}

.mngHeadTxt {
  text-align: center;
  color: #2550AC;
  font-size: 26px;
  font-weight: 600;

}

.avblty-btns {
  display: flex;
  justify-content: flex-end;
}

.avblty-btns>button {
  border: none;
  margin-left: 1em;
  margin-top: -4.8em;
  margin-bottom: 2em;
}

.shftListTag {
  color: #2550AC;
  font-weight: 700;
  margin: .5em 0;
}

.mngmtCard {
  background: #F0F2F6;
  border-radius: 30px;
  padding: .5em 1em;
  display: flex;
  margin-top: .5em;
  justify-content: space-between;
}

.mngmtListActionBtn {
  display: flex;
  justify-content: center;
}

.mngmtListActionBtn>button {
  margin-left: 1em;
  margin-top: 1em;
}

.mngmtCrdStatus {
  display: flex;
}

.mngmtCard .mngmtCrdS3 {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  min-width: 200px;
  margin: 10px 30px 0 0;
}

.mngmtCrdStatus {
  display: flex;
  justify-content: space-between;
  font-size: 14px;
}

.mngmtCard .mngmtCrdS2,
.mngmtCard .mngmtCrdS1 {
  display: flex;
  align-items: center;

}

.mngmtCard .mngmtCrdS1 {
  min-width: 180px;
}

.mngmtCard .mngmtCrdS2 {
  min-width: 200px;
}

.mngmtCard .mngmtCrdLine {
  width: 1px;
  background: #96A2BCCC;
  position: relative;

}

.mngmtCard .mngmtCrdLine>img {
  position: absolute;
  left: -38px;
  top: 4px;
}

.mngmtCrdText {
  margin-left: 1em;
  margin-top: 15px;
  font-size: 14px;
  font-weight: bold;
  max-width: 140px;
}

.tradeShiftIcon {
  height: 36px;
  width: 36px;
  border-radius: 50%;
  background-color: #FFFFFF;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 22px;
  left: -18px;

}

.mngmtCard .mngmtCrdS2 .mngmtCrdText {
  max-width: 180px;
}

.mngmtCard .typeTitle {
  font-weight: 600;
}

.mngmtCard .crdStatus>span {
  font-weight: 600;
  color: #1EA009;
}

.mngmtCard .crdType>span {
  color: #E5B300;
  font-weight: 600;
  margin: 0 .5em;
}

.cmnFormHead {
  display: flex;
  justify-content: space-between;
  padding: 1em 1em 1.5em 1em;
  border-bottom: 1px solid #A1B2D340;
  align-items: center;
}

.cmnFormHeadLeft {
  display: flex;
  align-items: center;

}

.cmnFormTxt {
  font-size: 22px;
  font-weight: 600;
  color: #2550AC;
  margin-left: 60px;
}

.cmnFormCloseBtn {
  border: none;
  width: 35px;
  height: 35px;
  border-radius: 50%;
  font-weight: 600;
  background: #D9D9D9;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto;
}

.cmnFormActBtn>button {
  margin-left: 1em;
  padding-left: 2em;
  padding-right: 2em;
}

.cmnFormCloseBtn img {
  height: 12px;
  width: 12px;
}

.availability-days .switchTxt {
  font-weight: 600;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 12px;

  height: 100%;
  color: #ffffff;
}

.availability-days .switchLbl {
  color: #565656;
  font-weight: 600;
  margin-right: .2em;
}

.availability-card .input-block__base-input_textarea {
  height: auto !important;
}

.cln-avlblty-btn {
  background: #EEF4FA;
  padding: .8em 1em;
  border: none;
  color: black;
}

.cmnBadge {
  color: #2C95FE;
  height: 25px;
  width: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  background-color: #FFFFFF !important;
  margin-left: .5em;

}

.multiple_shift_seperator {
  height: auto;
  overflow: auto;
  border-top: 2px solid #F7EEEE;
  border-left: 2px solid #F7EEEE;
  height: 430px;
  color: black;
}

.no-data-tag {
  color: #2550AC;
  font-size: 20px;
  font-weight: 600;
}

.RBS-Scheduler-config b {
  cursor: pointer;
}

.shift-move-btns {
  display: flex;

  align-items: center;
  justify-content: center;
}

.shift_modal .modal-dialog {
  width: 600px;
}

.is_user_logged_in {
  text-align: center;
  border: 3px solid #1EA009;
  animation: logged-in-animation 1.6s infinite;
}

.shift_publish_note {
  margin-top: 10px;
  display: flex;
  justify-content: center;
  font-size: 12px;
  font-weight: 600;
  container-type: inline-size;
  container-name: publish_note;
}

.shift_detail_container {
  margin-left: 3px;
  font-weight: 800;

  >.status {
    font-size: 10px;
    border-radius: 10px;
    color: #fff;
    max-width: fit-content;

    &.success {
      background-color: #066f06;
    }

    &.danger {
      background-color: #ee4141;
    }

    &.warning {
      background-color: #eeb741;
    }
  }

  >.name {
    font-size: 12px;
  }

  >.details {
    font-size: 10px;
  }
}

.pending-shift-status {
  background-color: darkorange;
  color: #fff;
  border-radius: 15px;
  padding: 5px 10px;
  font-weight: 600;
  font-size: 14px;
}

.shift-assignee {
  background: #f9faf5;
  border-radius: 10px;
  color: #2550ac;
  font-weight: 700;
  font-size: 14px;
  padding: .6rem;
}

@keyframes logged-in-animation {
  0% {
    -webkit-box-shadow: 0 0 0 0 #93ecad;
    -moz-box-shadow: 0 0 0 0 #93ecad;
    box-shadow: 0 0 0 0 #93ecad
  }

  100% {
    -webkit-box-shadow: 0 0 0 2px #93ecad;
    -moz-box-shadow: 0 0 0 2px #93ecad;
    box-shadow: 0 0 0 2px #93ecad
  }
}

@media screen and (width > 1400px) {
  .slot-text-big-screen {
    width: 100%;
    display: flex;
    justify-content: center;
  }
}