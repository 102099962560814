.landingPageWrapp .coreCol {
    background: var(--white);
    padding: 32px;
    border-radius: 30px;
    transition: all 0.3s ease 0s;
    border: 1px solid rgba(112,112,112,0.2);
    position: relative;
    overflow: hidden;
    height: 100%;
    z-index: 1;
}
.landingPageWrapp .coreCol::before {
    background-color: var(--primary);
    position: absolute;
    content: "";
    width: 40px;
    height: 40px;
    transform: rotate(45deg);
    right: 70px;
    bottom: -50px;
    transition: all 0.3s ease 0s;
}
.landingPageWrapp .coreCol::after {
    border: 1px solid var(--primary);
    position: absolute;
    content: "";
    width: 40px;
    height: 40px;
    transform: rotate(45deg);
    right: 78px;
    bottom: -50px;
    transition: all 0.3s ease 0s;
}
.landingPageWrapp .coreCol.blue::before {
    background-color: #E3F6FE;
}
.landingPageWrapp .coreCol.blue::after,
.landingPageWrapp .coreCol.blue:hover {
    border-color: #E3F6FE ;
}
.landingPageWrapp .coreCol.orange::before {
    background-color: #FEEEDC;
}
.landingPageWrapp .coreCol.orange::after,
.landingPageWrapp .coreCol.orange:hover {
    border-color: #FEEEDC ;
}
.landingPageWrapp .coreCol.green::before {
    background-color: #C8EDC8;
}
.landingPageWrapp .coreCol.green::after,
.landingPageWrapp .coreCol.green:hover {
    border-color: #C8EDC8 ;
}
.landingPageWrapp .coreCol.pink::before {
    background-color: #FDE2F5;
}
.landingPageWrapp .coreCol.pink::after,
.landingPageWrapp .coreCol.pink:hover {
    border-color: #FDE2F5 ;
}
.landingPageWrapp .coreCol:hover {
    border: 1px solid var(--primary);
}
.landingPageWrapp .coreCol:hover::before,
.landingPageWrapp .coreCol:hover::after {
    bottom: -21px;
}
.landingPageWrapp .coreCol > span {
    background: #f4f4f4;
    width: 48px;
    line-height: 48px;
    border-radius: 48px;
    display: inline-block;
    text-align: center;
    font-size: 18px;
    font-weight: 600;
    position: absolute;
    right: 32px;
    top: 32px;
}
.landingPageWrapp .coreCol.blue > span {
    background: #E3F6FE;
}
.landingPageWrapp .coreCol.orange > span {
    background: #FEEEDC;
}
.landingPageWrapp .coreCol.green > span {
    background: #C8EDC8;
}
.landingPageWrapp .coreCol.pink > span {
    background: #FDE2F5;
}
.landingPageWrapp .coreColIcon {
    margin-bottom: 24px;
}
.landingPageWrapp .coreColIcon i {
    display: inline-block;
    width: 99px;
    line-height: 99px;
    border-radius: 99px;
    overflow: hidden;
}
.landingPageWrapp .coreColIcon i img {
    width: auto;
    line-height: inherit;
    border-radius: inherit;
}
.landingPageWrapp .coreColInfo h4 {
    font-size: 20px;
    font-family: 'Bold' !important;
    color: #535353;
    line-height: 36px;
    margin-bottom: 20px;
    min-height: 32px;
}
.landingPageWrapp .coreColInfo p {
    color: #828282;
    line-height: 34px;
    margin: 0;
}

@media (max-width:1399px) {
    .landingPageWrapp .coreCol {
        padding: 24px;
        border-radius: 24px;
    }
    .landingPageWrapp .coreCol > span {
        right: 24px;
        top: 24px;
    }
}


@media (max-width:767px) {
    .landingPageWrapp .coreColInfo h4 {
        min-height: auto;
    }
    .landingPageWrapp .coreColInfo p {
        line-height: 30px;
    }
}