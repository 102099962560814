.form_row {
    /* margin-bottom:'20px';
    padding:'15px 0px';
    background-color:'#eee'; */

    padding: 15px 0px;
    background-color: #f9fafc;
    margin-bottom: 20px;
    position: relative;
}

.btn_action {
    display: inline-block !important;
    /* cursor: pointer; */
    border: 1px solid transparent;
    border-color: none !important;
    border-radius: 50% !important;
    color: #fff !important;
    padding: 3px;
    font-size: 12px;
}

.form_select {
    /* style={{width:'100%',padding:'6px 12px',height:'40px',}} */
    width: 100%;
    padding: 6px 12px;
    height: 40px;
    background-color: #fff;
    border: 1px solid #cccccc;
    border-radius: 4px;
    box-shadow: none;
    margin-top: 10px;
    outline: none !important;
    color: #bbb;
}

.set_checkbox_input {
    width: 40px;
    height: 40px;
    /* border: 1px solid #cccccc; */
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    border-radius: 10px;
}
.input_checkbox {
    cursor: pointer;
    width: 15px;
    height: 15px;
}

.set_signing_order {
    font-size: 18px;
    font-weight: bold;
    margin-left: 8px;
}

.required_field {
    display: flex;
    align-items: center;
}
input::placeholder{
   color:#565656;
}

input{
    color:#333333;
 }


 .default_select{
     color: #bbb;
 }

 .selected{
     color: #333333;
 }

.required {
    margin: 0px 5px;
    color: #f00;
    font-weight: bold;
}

.form-control[readonly] {
    background-color: none !important ;
    color: none !important;
    cursor: not-allowed;
}

.drag_image_container {
    /* style={{width:'30px',height:'30px',backgroundColor:'red',border:'2px solid black'}} */
    width: 30px;
    height: 30px;
    background-color: #fff;
    border-radius: 5px;
    /* border: 1px solid #f0f4f7; */
    position: absolute;
    top: 40px;
    left: -15px;
    display: flex;
    justify-content: center;
    align-items: center;
    /* cursor: move;
    cursor: grab; */
}

.drag_image_container_move {
    /* style={{width:'30px',height:'30px',backgroundColor:'red',border:'2px solid black'}} */
    width: 30px;
    height: 30px;
    background-color: #fff;
    border-radius: 5px;
    border: 1px solid #f0f4f7;
    position: absolute;
    top: 40px;
    left: -15px;
    display: flex;
    justify-content: center;
    align-items: center;
    /* border: 2px solid red; */
    cursor: move !important;
}

.drag_image {
    width: 60%;
}

.sign_order {
    position: absolute;
    left: -28px;
    top: 45px;
}

.btn_add_signer {
    width: 160px;
    height: 45px;
    margin: 15px 0px 0 0px;
    /* padding: 11px 22px 12px 23px; */
    border-radius: 5px;
    background-color: #447df7;
    font-size: 16px;
    font-weight: 500;
    color: #f9fafc;
    border: none !important;
    outline: none;
    display: flex;
    align-items: center;
    justify-content: space-around;
}

.add_signer_container{
    display: flex;
    justify-content: center;
    align-items: center;
}
