.landingPageWrapp .fea2ColOuter {
    padding: 24px 24px 12px 24px;
    position: relative;
    border-radius: 15px;
    overflow: hidden;
    height: 100%;
    z-index: 1;
}
.landingPageWrapp .fea2ColOuter::before {
    background: #E3F6FE;
    position: absolute;
    content: "";
    left: 0;
    top: 0;
    right: 0;
    height: 280px;
    border-radius: 15px;
    z-index: -1;
}
.landingPageWrapp .fea2ColOuter.orange::before {
    background: #FEEEDC;
}
.landingPageWrapp .fea2ColOuter.green::before {
    background: #C8EDC8;
}
.landingPageWrapp .fea2ColOuter.pink::before {
    background: #FDE2F5;
}
.landingPageWrapp .fea2ColOuter.red::before {
    background: #FFD7C9;
}
.landingPageWrapp .fea2Col {
    background: var(--white);
    padding: 24px;
    border-radius: 8px;
    transition: all 0.3s ease 0s;
    height: 100%;
    box-shadow: 0 0 8px 4px rgba(0,0,0,0.05);
}
.landingPageWrapp .fea2ColIcon {
    margin-bottom: 24px;
}
.landingPageWrapp .fea2ColIcon i {
    display: inline-block;
    width: 88px;
    line-height: 88px;
    border-radius: 8px;
    text-align: center;
}
.landingPageWrapp .fea2ColIcon.blue i {
    background: #E3F6FE;
}
.landingPageWrapp .fea2ColIcon.orange i {
    background: #FEEEDC;
}
.landingPageWrapp .fea2ColIcon.green i {
    background: #E2FDE3;
}
.landingPageWrapp .fea2ColIcon.pink i {
    background: #FDE3F5;
}
.landingPageWrapp .fea2ColIcon.red i {
    background: #FFEBE4;
}
.landingPageWrapp .fea2ColInfo h4 {
    font-size: 22px;
    font-family: 'Bold' !important;
    line-height: 34px;
    margin-bottom: 12px;
}
.landingPageWrapp .fea2ColInfo p {
    color: #828282;
    line-height: 28px;
    margin: 0;
}

@media (max-width:1199px) {
    .landingPageWrapp .fea2Col {
        padding: 24px;
        border-radius: 24px;
    }
}