.landingPageWrapp .coreCol {
    background: var(--white);
    padding: 32px;
    border-radius: 30px;
    transition: all 0.3s ease 0s;
    border: 1px solid rgba(112,112,112,0.4);
    position: relative;
    overflow: hidden;
    height: 100%;
    z-index: 1;
}
.landingPageWrapp .coreCol::before {
    background-color: var(--primary);
    position: absolute;
    content: "";
    width: 40px;
    height: 40px;
    transform: rotate(45deg);
    right: 70px;
    bottom: -50px;
    transition: all 0.3s ease 0s;
}
.landingPageWrapp .coreCol::after {
    border: 1px solid var(--primary);
    position: absolute;
    content: "";
    width: 40px;
    height: 40px;
    transform: rotate(45deg);
    right: 78px;
    bottom: -50px;
    transition: all 0.3s ease 0s;
}
.landingPageWrapp .coreCol:hover {
    border: 1px solid var(--primary);
}
.landingPageWrapp .coreCol:hover::before,
.landingPageWrapp .coreCol:hover::after {
    bottom: -21px;
}
.landingPageWrapp .coreColIcon {
    margin-bottom: 24px;
}
.landingPageWrapp .coreColIcon i {
    display: inline-block;
    width: 99px;
    line-height: 99px;
    border-radius: 99px;
    overflow: hidden;
}
.landingPageWrapp .coreColIcon i img {
    width: 99px;
    line-height: 99px;
    border-radius: 99px;
}
.landingPageWrapp .coreColInfo h4 {
    font-size: 20px;
    font-family: 'Bold' !important;
    color: #535353;
    line-height: 36px;
    margin-bottom: 20px;
    min-height: 32px;
}
.landingPageWrapp .coreColInfo p {
    color: #828282;
    line-height: 34px;
    margin: 0;
}

@media (max-width:1399px) {
    .landingPageWrapp .coreCol {
        padding: 24px;
        border-radius: 24px;
    }
}


@media (max-width:767px) {
    .landingPageWrapp .coreColInfo h4 {
        min-height: auto;
    }
    .landingPageWrapp .coreColInfo p {
        line-height: 30px;
    }
}