.landingPageWrapp .listFeaCol {
    display: flex;
}
.landingPageWrapp .listFeaIcon span {
    background: #f4f4f4;
    width: 32px;
    line-height: 32px;
    border-radius: 32px;
    display: inline-block;
    margin-right: 10px;
    text-align: center;
}
.landingPageWrapp .listFeaIcon.blue span {
    background: transparent linear-gradient(355deg, #2B70FA 0%, #84BCFA 100%) 0% 0% no-repeat padding-box;
}
.landingPageWrapp .listFeaIcon.green span {
    background: transparent linear-gradient(355deg, #31C369 0%, #A0EAC2 100%) 0% 0% no-repeat padding-box;
}
.landingPageWrapp .listFeaIcon.red span {
    background: transparent linear-gradient(355deg, #F8452D 0%, #F7B8AF 100%) 0% 0% no-repeat padding-box;
}
.landingPageWrapp .listFeaIcon.pink span {
    background: transparent linear-gradient(355deg, #9B2CFA 0%, #D8AAFF 100%) 0% 0% no-repeat padding-box;
}
.landingPageWrapp .listFeaInfo h4 {
    font-size: 18px;
    font-weight: 700;
    margin-top: 0;
    margin-bottom: 6px;
}
.landingPageWrapp .listFeaInfo p {
    font-size: 15px;
    line-height: 20px;
    margin: 0;
}