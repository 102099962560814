.btn {
  border-width: $border-thin;
  background-color: $transparent-bg;
  font-weight: $font-weight-normal;
  font-size: $font-size-base;
  border-radius: 10px;

  @include opacity(0.8);
  padding: 8px 16px;

  @include btn-styles($default-btn-color, $primary-states-colorv2);

  &:hover {
    outline: 0 !important;
    @include btn-styles($color-black-v2, $primary-states-color-hoverv2);
  }

  &:focus {
    outline: 0 !important;
    @include btn-styles($default-btn-color, $primary-states-colorv2);
  }

  &:active,
  &.active,
  .open>&.dropdown-toggle {
    @include box-shadow(none);
    outline: 0 !important;
  }

  &.btn-icon {
    @include btn-styles($icon-color, $icon-states-color);
    padding: 8px !important;
    width: 40px;
  }

  &.btn-icon-sm {
    @include btn-styles($icon-color, $icon-states-color);
    padding: 4px !important;

    i {
      color: $color-blue-v2;
    }
  }

  &.btn-icon-sm-delete {
    @include btn-styles($icon-color, $icon-states-color);
    background-color: $icon-states-color-delete;
    padding: 4px !important;

    i {
      color: $color-red;
    }

    &.btn-icon-sm-delete:hover,
    &.btn-icon-sm-delete:focus {
      background-color: $icon-states-color-delete;
    }
  }

  &.btn-grey {
    background: #EEF2F9;
    color: #2550AC;
    border: none;
  }

  &.btn-reject {
    background: #FDD8D8;
    color: #FF0A0A;
    border: 1px solid #FF0A0A;
    padding-left: 1em;
    padding-right: 1em;
  }

  &.btn-reject:hover {
    background-color: #FDD8D8;
    color: #FF0A0A;
  }

  &.no-btn-border {
    border: none;
  }

  &.btn-schedule {
    background: linear-gradient(0deg, #FAFAFA, #FAFAFA),
      linear-gradient(0deg, #FFFFFF, #FFFFFF);
    border-radius: 10px;
    border: none;
    color: #000000;
    font-weight: 600;
    opacity: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 3em;
    padding: 2px 8px !important;

    img {
      margin-right: 10px;
    }
  }

  &.btn-scheduleSm {
    background: #F3F4ED;
    min-height: 1em !important;
    height: 2.5em !important;
    color: #717171;

    &:hover,
    &:active,
    &.active,
    &:active:hover,
    &.active:hover {
      background: #F3F4ED;
      color: #000000;
    }
  }

  .btn-label {
    .fa {
      width: 13px;
    }
  }

  .fa {
    width: 20px;
  }
}

// Apply the mixin to the buttons
//.btn-default { @include btn-styles($default-color, $default-states-color); }
.btn-primary {
  @include btn-styles($default-btn-color, $primary-states-colorv2);
}

.btn-success {
  @include btn-styles($success-color, $success-states-color);
}

.btn-info {
  @include btn-styles($info-color, $info-states-color);
}

.btn-warning {
  @include btn-styles($warning-color, $warning-states-color);
}

.btn-danger {
  @include btn-styles($danger-color, $danger-states-color);
}

.btn-neutral {
  @include btn-styles($white-color, $white-color);

  &:active,
  &.active,
  .open>&.dropdown-toggle {
    background-color: $white-color;
    color: $default-color;
  }

  &.btn-fill,
  &.btn-fill:hover,
  &.btn-fill:focus {
    color: btn-styles;
  }

  &.btn-fill:hover,
  &.btn-fill:focus {
    @include opacity(0.86);
  }

  &.btn-simple:active,
  &.btn-simple.active {
    background-color: transparent;
  }
}

.btn {

  &:disabled,
  &[disabled],
  &.disabled {
    @include opacity(0.5);
    cursor: no-drop;
    color: inherit;
    pointer-events: all;
  }
}

.btn-fill {

  &:disabled,
  &[disabled],
  &.disabled {
    color: $white-color;
  }
}

.btn-round {
  border-width: $border-thin;
  border-radius: $btn-round-radius !important;
  padding: $padding-round-vertical $padding-round-horizontal;

  &.btn-icon {
    padding: $padding-round-vertical;
  }
}

.btn-round-smaller {
  border-width: 1px;
  border-radius: 25px !important;
  padding: 3px 8px;

  &.btn-icon {
    padding: $padding-round-vertical;
  }
}

.btn-simple {
  border: $none;
  font-size: $font-size-medium;
  // padding: $padding-base-vertical $padding-base-horizontal;

  // &.btn-icon {
  //   padding: $padding-base-vertical;
  // }
}

.btn-lg {
  // @include btn-size(
  //   $padding-large-vertical,
  //   $padding-large-horizontal,
  //   $font-size-large,
  //   $border-radius-large
  // );
  // font-weight: $font-weight-normal;
  width: 100%;
}

.btn-mute {
  background-color: $color-mute !important;
  color: $color-blue-v2 !important;
  // min-width: 127px;
  @include btn-styles(none, none);
}

.btn-mute {

  &:hover,
  &:focus {
    @include btn-styles($color-black-v2, $color-blue-v2);
    // border-color: none !important;
  }
}

.btn-sm {
  @include btn-size($padding-small-vertical,
    $padding-small-horizontal,
    $font-size-small,
    $border-radius-small );
}

.btn-xs {
  @include btn-size($padding-xs-vertical,
    $padding-xs-horizontal,
    $font-size-small,
    $border-radius-small );
}

.btn-wd {
  min-width: 120px;
}

.btn-group.select {
  width: 100%;
}

.btn-group.select .btn {
  text-align: left;
}

.btn-group.select .caret {
  position: absolute;
  top: 50%;
  margin-top: -1px;
  right: 8px;
}

.btn-group>.btn {
  opacity: 1;

  &:not(:first-child):not(.dropdown-toggle) {
    border-left: none;
  }
}

.custom-style-userlist .btn:focus,
.custom-style-userlist .btn.show {
  background-color: #2f5da1 !important;
  border-color: #2f5da1 !important;
  color: white !important;
  opacity: 1;
}