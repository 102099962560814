.landingPageWrapp .infoCol {
    display: flex;
}
.landingPageWrapp .infoCol span {
    display: inline-block;
    min-width: 73px;
    margin-right: 40px;
}
.landingPageWrapp .infoColDes h4 {
    font-size: 26px;
    margin-bottom: 16px;
}
.landingPageWrapp .infoColDes p {
    font-size: 16px;
    line-height: 32px;
    margin: 0;
}

@media (max-width:1199px) {
    .landingPageWrapp .infoCol span {
        margin-right: 24px;
    }
    .landingPageWrapp .infoColDes h4 {
        font-size: 24px;
        margin-bottom: 12px;
    }
    .landingPageWrapp .infoColDes p {
        line-height: 26px;
    }
}