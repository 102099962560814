.landingPageWrapp .reasonCol {
    background: var(--white);
    padding: 32px;
    border-radius: 32px;
    transition: all 0.3s ease 0s;
    position: relative;
    overflow: hidden;
    height: 100%;
    z-index: 1;
}
.landingPageWrapp .reasonCol.yellow {
    background: #FFF8EE;
}
.landingPageWrapp .reasonCol.blue {
    background: #E9FBFF;
}
.landingPageWrapp .reasonCol.green {
    background: #E3F5F8;
}
.landingPageWrapp .reasonCol.orange {
    background: #FFF1F1;
}
.landingPageWrapp .reasonCol.pink {
    background: #F5E4EA;
}
.landingPageWrapp .reasonColIcon {
    margin-bottom: 24px;
}
.landingPageWrapp .reasonColIcon i {
    display: inline-block;
    width: 99px;
    line-height: 99px;
}
.landingPageWrapp .reasonColIcon i img {
    max-width: 100%;
}
.landingPageWrapp .reasonColInfo h4 {
    font-size: 28px;
    font-family: 'Bold' !important;
    color: var(--secondary);
    line-height: 40px;
    margin-bottom: 20px;
    min-height: 72px;
}
.landingPageWrapp .reasonColInfo p {
    font-size: 18px;
    color: #535353;
    line-height: 32px;
    margin-bottom: 20px;
}
.landingPageWrapp .reasonColInfo a {
    font-size: 15px;
    color: var(--primary);
    font-family: 'Bold' !important;
    transition: all 0.3s ease 0s;
}
.landingPageWrapp .reasonColInfo span {
    display: inline-block;
    transition: all 0.3s ease 0s;
    margin-right: 6px;
}
.landingPageWrapp .reasonColInfo a:hover {
    color: var(--primary);
}
.landingPageWrapp .reasonColInfo a:hover span {
    margin-right: 12px;
}
.landingPageWrapp .reasonCol.noLink .reasonColInfo a {
    display: none;
}

@media (max-width:1199px) {
    .landingPageWrapp .reasonCol {
        padding: 32px;
        border-radius: 24px;
    }
    .landingPageWrapp .reasonColInfo h4 {
        font-size: 24px;
        line-height: 36px;
        margin-bottom: 16px;
        min-height: 72px;
    }
}