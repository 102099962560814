.passwordUpdateBlock {
    background: transparent linear-gradient(179deg, #F6F6FF 0%, #EAF8FF 100%) 0% 0% no-repeat padding-box;
    position: relative;
    z-index: 1;
    max-height: 100vh;
}

.passwordUpdateBlock::before {
    position: absolute;
    content: "";
    background: url("../../../assets/images/homeContactBg.png") no-repeat;
    width: 100%;
    height: 448px;
    bottom: 40px;
    background-size: contain;
    background-position: right;
    z-index: -1;
}

.banner-container {
    display: block;
}

.tips-container {
    display: block;

    &>div {
        background-color: #E3F5F8;
        border: 2px solid #515253;
        border-radius: 10px;
        padding: 20px;
    }

    &>div>p {
        font-weight: 600;
        line-height: 15px;
        text-decoration: underline;
    }

    &>div>ul {
        padding-left: 15px;
    }

    &>div>ul>li {
        list-style-type: disc;
        font-size: 12px;
        font-weight: 700;
    }
}

.error-block {
    color: red;
    float: right;
    font-size: 12px;
    padding: 5px;
    font-weight: 600;
}

.form-container {
    border: 2px solid goldenrod;
    border-radius: 15px;
    min-height: 70vh;
}

.multi-account-admin-header {
    display: flex;
    flex-direction: column;
    align-items: center;

    >span {
        margin-bottom: 10px;
        font-size: 16px;
        font-weight: 600;
        color: #2550AC;
    }
}

.multi-account-admin-container {
    >.image-container {
        display: flex;
        justify-content: center;

        >img {
            height: 150px;
        }
    }

    span {
        display: flex;
        justify-content: center;
        padding: 10px;
        font-size: 16px;
        font-weight: 400;
        text-align: center;

        >.inner_text {
            display: contents;
            text-transform: capitalize;
            font-weight: 700;
        }
    }
}

.disclaimer_text {
    font-size: 12px;
    padding: 10px;
    text-align: center;
}

@media screen and (max-width: 992px) {
    .banner-container {
        display: none;
    }

    .multi-account-admin-container {
        >.image-container {
            display: flex;
            justify-content: center;

            >img {
                height: 100px;
            }
        }

        span {
            display: flex;
            justify-content: center;
            padding: 2px;
            font-size: 10px;
            font-weight: 400;
            text-align: center;

            >.inner_text {
                display: contents;
                text-transform: capitalize;
                font-weight: 700;
            }
        }
    }

    .disclaimer_text {
        font-size: 9px;
        padding: 10px;
        text-align: center;
    }
}

@media screen and (max-width: 767px) {
    .tips-container {
        display: none;
    }
}

.drop-down-item-disabled {
    opacity: 0.6;
    cursor: no-drop;
}

.group-management-header {
    >div {
        display: flex;
        justify-content: space-evenly;

        >button {
            padding: 8px;
        }

        >input {
            margin: 0 3px;
            height: 40px;
            border-radius: 10px;
            padding: 5px 16px;
            background-color: #F9FAF5;
            outline: none !important;
            border: 0px;
            width: 80%;

            &:focus,
            &:hover {
                background-color: #fff;
                border: 2px solid #707172 !important;
            }
        }
        >input:disabled{
            cursor: no-drop;
            background-color: #F9FAF5;
        }
    }
}

.group-management-assignee_list {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    & > span{
        border-radius: 5px;
        border: 1px solid #707172;
        margin: 2px;
        padding: 1px 3px;
        background-color: #E1E1E1;
        font-weight: 600;
    }
}

.customer-login-form .react-select__control {
    height: 55px;
    border-radius: 30px;
}