.landingPageWrapp .contactBlock {
    background: transparent linear-gradient(179deg, #F6F6FF 0%, #EAF8FF 100%) 0% 0% no-repeat padding-box;
    position: relative;
    z-index: 1;
}
.landingPageWrapp .contactBlock::before {
    position: absolute;
    content: "";    
    background: url("../../assets/images/homeContactBg.png") no-repeat;
    width: 100%;
    height: 448px;
    bottom: 40px;
    background-size: contain;
    background-position: right;
    z-index: -1;
}