.landingPageWrapp .iconBoxCol {
    background: #fff;
    padding: 24px;
    border-radius: 8px;
    transition: all 0.3s ease 0s;
    border: 1px solid transparent;
    position: relative;
    overflow: hidden;
    height: 100%;
    text-align: center;
    z-index: 1;
}
.landingPageWrapp .iconBoxCol.pink {
    background: #fef9fd;
}
.landingPageWrapp .iconBoxCol.blue {
    background: #eaf2fb;
}
.landingPageWrapp .iconBoxCol.green {
    background: #ecf1ee;
}
.landingPageWrapp .style2.iconBoxCol {
    box-shadow: 0px 20px 40px #0b0d3914;
}
.landingPageWrapp .iconBoxColIcon {
    margin-bottom: 16px;  
}
.landingPageWrapp .iconBoxColIcon i {
    display: inline-block;
    overflow: hidden;
}
.landingPageWrapp .style2 .iconBoxColIcon i {
    width: 140px;
    line-height: 80px;
    border-radius: 140px;
    padding: 30px;
    height: 140px;
}
.landingPageWrapp .iconBoxColIcon.blue i {
    background-color: #E3F6FE;
}
.landingPageWrapp .iconBoxColIcon.orange i {
    background-color: #FEEEDC;
}
.landingPageWrapp .iconBoxColIcon.green i {
    background-color: #C8EDC8;
}
.landingPageWrapp .iconBoxColIcon i img {
    max-width: 100%;
}
.landingPageWrapp .iconBoxColInfo h4 {
    font-size: 20px;
    font-family: 'Bold' !important;
    color: #242424;
    line-height: 28px;
    margin-bottom: 12px;
}
.landingPageWrapp .iconBoxColInfo p {
    font-size: 15px;
    color: #242424;
    line-height: 26px;
    margin: 0;
}


@media (max-width:767px) {
    .landingPageWrapp .iconBoxColInfo h4 {
        min-height: auto;
    }
    .landingPageWrapp .iconBoxColInfo p {
        line-height: 30px;
    }
}