.landingPageWrapp .pageImg {
    position: relative;
    text-align: center;
    z-index: 1;
}
.landingPageWrapp .pageImg.imgBorder {
    padding-left: 30px;
    padding-bottom: 30px;
}
.landingPageWrapp .pageImg.imgBorder::before {
    position: absolute;
    content: "";
    border: 4px solid var(--primary);
    left: 0;
    top: 20px;
    right: 30px;
    bottom: 0;
    z-index: -1;
}