.landingPageWrapp .sclIcons span {
    display: inline-block;
    margin-right: 24px;
}
.landingPageWrapp .sclIcons a {
    font-size: 18px;
    color: var(--secondary);
    background-color: var(--white);
    border: 1px solid #DCDCE3;
    width: 44px;
    line-height: 42px;
    border-radius: 44px;
    text-align: center;
    transition: all 0.4s ease 0s;
}
.landingPageWrapp .sclIcons.small a {
    font-size: 16px;
    width: 40px;
    line-height: 38px;
    border-radius: 40px;
}
.landingPageWrapp .sclIcons a + a {
    margin-left: 15px;
}
.landingPageWrapp .sclIcons a:hover {
    background-color: var(--primary);
    color: var(--white);
    border-color: var(--primary);
}

@media (max-width:991px) {
    .landingPageWrapp .sclIcons span {
        margin-right: 16px;
    }
}
@media (max-width:767px) {
    .landingPageWrapp .sclIcons span {
        margin-right: 12px;
    }
}