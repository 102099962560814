.landingPageWrapp .mainHeader {
    position: relative;
    z-index: 9;
}

.landingPageWrapp .hdrTopBar {
    height: 44px;
    background: var(--secondary);
    padding: 7px 0;
}
.landingPageWrapp .hdrTopBar p {
    font-size: 14px;
    color: var(--white);
    letter-spacing: 2.11px;
    text-align: center;
    margin: 0;
}
.landingPageWrapp .offcanvasBtn {
    display: inline-block;
    margin-left: 20px;
}
.landingPageWrapp button.btn-menuBar {
    padding: 0;
    margin: 0;
    display: inline-block;
    width: 41px;
    height: 25px;
    transition: all 0.4s ease 0s;
}
.landingPageWrapp button.btn-menuBar:focus {
    box-shadow: none;
}
.landingPageWrapp .btn-menuBar span {
    background: var(--secondary);
    width: 25px;
    height: 3px;
    border-radius: 3px;
    display: block;
    transition: all 0.4s ease 0s;
}
.landingPageWrapp .btn-menuBar span.bar1 {
    margin-left: 5px;
    background: var(--primary);
}
.landingPageWrapp .btn-menuBar span.bar2 {
    width: 39px;
    margin: 7px 0;
}
.landingPageWrapp .btn-menuBar span.bar3 {
    margin-left: 14px;
}
.landingPageWrapp .btn-menuBar:hover span.bar1 {
    margin-left: 14px;
}
.landingPageWrapp .btn-menuBar:hover span.bar3 {
    margin-left: 5px;
}
.landingPageWrapp .offcanvas-backdrop {
    background-color: var(--secondary);
}
.landingPageWrapp .offcanvas-backdrop.show {
    opacity: 0.85;
}
.landingPageWrapp .offcanvasOuter.offcanvas-start {
    width: 300px;
    border-right: 0;
}
.landingPageWrapp .offcanvasHeader.offcanvas-header {
    padding: 1.2rem 1.5rem;
    border-bottom: 1px solid #e5e5e5;
}
.landingPageWrapp .offcanvasHeader .btn-close {
    background: transparent
        url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23356DFF'%3e%3cpath d='M.293.293a1 1 0 011.414 0L8 6.586 14.293.293a1 1 0 111.414 1.414L9.414 8l6.293 6.293a1 1 0 01-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 01-1.414-1.414L6.586 8 .293 1.707a1 1 0 010-1.414z'/%3e%3c/svg%3e")
        center/1em auto no-repeat;
    opacity: 1;
    box-shadow: 0px 3px 10px 1px rgb(0 0 0 / 10%);
    width: 40px;
    line-height: 40px;
    height: 40px;
    padding: 0;
    display: inline-block;
    border-radius: 40px;
    color: var(--secondary);
    background-size: 12px;
}
.landingPageWrapp .offcanvasOuter .btn-close:focus {
    box-shadow: none;
}
.landingPageWrapp .offcanvasBody.offcanvas-body {
    padding: 0 0 20px 0;
}
.landingPageWrapp .offcanvasBody .hdrMenu {
    display: block;
    margin-left: 0;
    margin-bottom: 0;
}
.landingPageWrapp .offcanvasBody .hdrMenu > ul > li {
    display: block;
    border-bottom: 1px solid #e8e8e8;
}
.landingPageWrapp .offcanvasBody .hdrMenu > ul > li + li {
    margin-left: 0;
    margin-top: 0;
}
.landingPageWrapp .offcanvasBody .hdrMenu > ul > li > a {
    font-size: 13.5px;
    line-height: 46px;
    padding: 0 15px;
    display: block;
}
.landingPageWrapp .offcanvasBody .hdrMenu > ul > li > a {
    background: #ffffff;
}
.landingPageWrapp .offcanvasBody .hdrMenu > ul > li > a:hover {
    background: #f5f5f5;
}
.landingPageWrapp .offcanvasBodyInner .menuBtn {
    margin-left: 0;
    margin-right: 0;
}
.landingPageWrapp .hdrScl {
    text-align: center;
}
.landingPageWrapp .hdrScl .sclIcons span {
    display: none;
}
.landingPageWrapp .offcanvasCpyrt {
    padding: 0 20px;
    text-align: center;
}
.landingPageWrapp .offcanvasCpyrt p {
    font-size: 14px;
    margin: 0;
}
.landingPageWrapp .offcanvasCpyrt p a {
    font-family: 'Bold' !important;
    text-transform: uppercase;
}
.landingPageWrapp .hdrMain {
    text-align: center;
}
.landingPageWrapp .hdrLogo {
    padding: 22px 0;
}
.landingPageWrapp .hdrMenu {
    display: inline-block;
    vertical-align: bottom;
}
.landingPageWrapp .hdrMenu > ul {
    margin: 0;
    padding: 0;
}
.landingPageWrapp .hdrMenu > ul > li {
    position: relative;
    display: inline-block;
    transition: all 0.4s ease 0s;
}
.landingPageWrapp .hdrMenu > ul > li + li {
    margin-left: 0;
}
.landingPageWrapp .hdrMenu > ul > li > a {
    font-size: 13px;
    color: #9e9e9e;
    line-height: 60px;
    transition: all 0.4s ease 0s;
    padding: 4px 12px 4px 12px;
    font-family: 'Bold' !important;
    letter-spacing: 0.6px;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 130px;
    white-space: nowrap;
}
.landingPageWrapp .hdrMenu > ul > li > a:hover,
.landingPageWrapp .hdrMenu > ul > li.current > a,
.landingPageWrapp .hdrMenu > ul > li.current > a:hover {
    color: var(--primary);
}
.landingPageWrapp .hdrMenu > ul > li.current > a,
.landingPageWrapp .hdrMenu > ul > li.current > a:hover {
    font-family: 'ExtraBold';
}
.landingPageWrapp .hdrMain .hdrMenu > ul > li > a:hover,
.landingPageWrapp .hdrMain .hdrMenu > ul > li.current > a,
.landingPageWrapp .hdrMain .hdrMenu > ul > li.current > a:hover {
    background: var(--primary);
    color: var(--white);
}
.landingPageWrapp .hdrMain .hdrMenu > ul > li > a:hover,
.landingPageWrapp .hdrMain .hdrMenu > ul > li.noNeeded > a,
.landingPageWrapp .hdrMain .hdrMenu > ul > li.noNeeded > a:hover {
    color: #9e9e9e;
}
.landingPageWrapp .hdrMenu > ul > li ul {
    background: #fff;
    position: absolute;
    min-width: 240px;
    display: none;
    text-align: left;
    padding: 0;
    border-radius: 4px;
    box-shadow: 0 8px 12px -2px rgb(0 0 0 / 10%);
    z-index: 9;
}
.landingPageWrapp .hdrMenu > ul > li:hover > ul {
    display: block;
}
.landingPageWrapp .hdrMenu > ul > li > ul li {
    position: relative;
}
.landingPageWrapp .hdrMenu > ul > li > ul li + li {
    border-top: 1px solid #f4f4f4;
}
.landingPageWrapp .hdrMenu > ul > li > ul li a {
    font-size: 14px;
    display: block;
    text-transform: uppercase;
    color: var(--secondary);
    padding: 0 16px;
    line-height: 44px;
    font-weight: 500;
    transition: all 0.3s ease 0s;
    border-radius: 4px;
}
.landingPageWrapp .hdrMenu > ul > li > ul li:first-child a {
    border-radius: 4px 4px 0 0;
}
.landingPageWrapp .hdrMenu > ul > li > ul li:last-child a {
    border-radius: 0 0 4px 4px;
}
.landingPageWrapp .hdrMenu > ul > li > ul li a:hover {
    background: var(--primary);
    color: #fff;
}
.landingPageWrapp .hdrMenu > ul > li > ul ul {
    top: 0;
    left: 100%;
}
.landingPageWrapp .hdrMenu > ul > li > ul > li:hover > ul {
    display: block;
}
.landingPageWrapp .menuBtn,
.landingPageWrapp .hdr-user {
    display: flex;
}

.landingPageWrapp .hdr-user {
    margin-right: 20px;
    vertical-align: middle;
}
.landingPageWrapp .user-drop-menu {
    background: #ffefe2;
    border-radius: 5px;
    position: relative;
    padding: 13px 33px 13px 70px;
    text-align: left;
    border: 0;
    min-width: 48px;
    min-height: 66px;
}
.landingPageWrapp .user-drop-menu:hover {
    background: var(--secondary);
}
.landingPageWrapp .user-drop .btn-check:active + .btn-success,
.landingPageWrapp .user-drop .btn-check:checked + .btn-success,
.landingPageWrapp .user-drop .btn-success.active,
.landingPageWrapp .user-drop .btn-success:active,
.landingPageWrapp .user-drop.show .btn-success.dropdown-toggle {
    color: #ffefe2;
    background-color: var(--secondary);
    border-color: var(--secondary);
}
.landingPageWrapp .user-drop .btn-check:active + .btn-success:focus,
.landingPageWrapp .user-drop .btn-check:checked + .btn-success:focus,
.landingPageWrapp .user-drop .btn-success.active:focus,
.landingPageWrapp .user-drop .btn-success:active:focus,
.landingPageWrapp .user-drop.show .btn-success.dropdown-toggle:focus,
.landingPageWrapp .user-drop.show .btn-success.dropdown-toggle {
    box-shadow: 0 0 0 0.25rem rgba(99, 53, 73, 0.5);
}
.landingPageWrapp .user-drop .btn-check:focus + .btn-success,
.landingPageWrapp .user-drop .btn-success:focus {
    color: #ffefe2;
    background-color: var(--secondary);
    border-color: var(--secondary);
    box-shadow: 0 0 0 0.25rem rgba(99, 53, 73, 0.5);
}
.landingPageWrapp .user-drop-menu::after {
    border-top-color: var(--secondary);
    position: absolute;
    right: 10px;
    top: 50%;
    margin-top: -3px;
}
.landingPageWrapp .user-drop-menu:hover::after,
.landingPageWrapp .user-drop.show .user-drop-menu.btn-success.dropdown-toggle:focus::after,
.landingPageWrapp .user-drop .user-drop-menu.btn-check:focus + .btn-success::after,
.landingPageWrapp .user-drop .user-drop-menu.btn-success:focus::after,
.landingPageWrapp .user-drop.show .btn-success.dropdown-toggle::after {
    border-top-color: #ffefe2;
}
.landingPageWrapp .user-drop-menu span {
    position: absolute;
    left: 13px;
    top: 9px;
    border: 2px solid var(--primary);
    border-radius: 50%;
}
.landingPageWrapp .user-drop-menu span img {
    width: 44px;
    height: 44px;
    border-radius: 50%;
}
.landingPageWrapp .user-drop-menu h3 {
    font-size: 16px;
    color: var(--secondary);
    font-weight: 600;
    margin: 0 0 4px 0;
    transition: all 0.3s ease 0s;
}
.landingPageWrapp .user-drop-menu:hover h3,
.landingPageWrapp .user-drop.show .user-drop-menu.btn-success.dropdown-toggle:focus h3,
.landingPageWrapp .user-drop .user-drop-menu.btn-check:focus + .btn-success h3,
.landingPageWrapp .user-drop .user-drop-menu.btn-success:focus h3,
.landingPageWrapp .user-drop.show .btn-success.dropdown-toggle h3 {
    color: #ffefe2;
}
.landingPageWrapp .user-drop-menu p {
    font-size: 14px;
    color: var(--primary);
    line-height: 16px;
    margin: 0;
}
.landingPageWrapp .user-dropdown-menu.dropdown-menu {
    border-radius: 12px;
    border-width: 3px 0 0 0;
    border-style: solid;
    border-color: var(--primary);
    padding: 0;
    margin-top: 11px;
    width: 100%;
    box-shadow: 0px 12px 48px 0px #0022330f;
    min-width: 240px;
}
.landingPageWrapp .user-dropdown-menu.dropdown-menu::before {
    content: '';
    position: absolute;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-bottom: 10px solid var(--primary);
    bottom: 100%;
    right: 10px;
}
.landingPageWrapp .user-dropdown-menu a {
    font-size: 16px;
    display: block;
    padding: 12px;
    color: var(--secondary);
    font-weight: 500;
    border-bottom: 1px solid #e1e1e1;
    transition: all 0.4s ease 0s;
}
.landingPageWrapp .user-dropdown-menu a:last-child {
    border-bottom: 0;
}
.landingPageWrapp .user-dropdown-menu a:hover {
    background: var(--primary);
    color: var(--white);
}
.landingPageWrapp .user-dropdown-menu a svg {
    width: 24px;
    height: 24px;
}
.landingPageWrapp .user-dropdown-menu a span {
    display: inline-block;
    margin-left: 8px;
}

.landingPageWrapp .language-switch.dropdown-menu-end.dropdown-menu {
    transform: inherit !important;
    margin-top: 10px !important;
    inset: inherit !important;
    min-width: 15rem;
}
.landingPageWrapp .language-switch.dropdown-menu-end.dropdown-menu .dropdown-item {
    font-size: 15px;
}
.landingPageWrapp .language-switch.dropdown-menu-end.dropdown-menu .dropdown-item span {
    margin-left: 6px;
}

@media (max-width: 1199px) {
    .landingPageWrapp .hdrMenu > ul > li > a {
        padding: 0 4px 6px 5px;
        font-size: 13px;
    }
}


@media (max-width: 767px) {
    .landingPageWrapp .mainHeader::before {
        display: none;
    }
    .landingPageWrapp .hdrTopBar {
        display: none;
    }
    .landingPageWrapp .hdrLogo {
        padding: 20px 0;
        text-align: center;
    }
    .landingPageWrapp .hdrMain {
        text-align: right;
        margin-top: 0 !important;
    }
}

.landingPageWrapp .show .dropdown-menu {
    opacity: 1 !important;
    filter: alpha(opacity=100);
    visibility: visible !important;
}
