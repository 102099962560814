.signerPage {
    width: 100%;
    height: 100%;
}

.signerPage .header {
    display: flex;
    justify-content: center;

}

.signerPage .header-details {
    text-align: left;
    width: 800px;
}

.signerPage h4 {
    font-weight: bolder;
}

.signerPage footer {
    position: fixed;
    left: 0;
    bottom: 0;
    width: 100%;
    background-color: white;
    color: white;
    height: 60px;
    padding-left: 220px;
    padding-right: 200px;
}

.signerPage .footer-items {
    display: flex;
    justify-content: center;
    margin: 15px;
}

.signerPage .icons- {
    display: flex;
    width: 83px;
    height: 30px;
    margin-left: 200px;
    justify-content: space-between;
}

.signerPage .footer-items img {
    width: 120px;
    height: 35px;

}

.signerPage .finishBtn {
    color: white !important;
    float: right;
    margin-left: auto;
}

.signerPage .content {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.signerPage .content2 {
    display: flex;
    flex-direction: column;
}

.signerPage .sign-img {
    position: relative;
    background-color: white;
    width: 800px;

}

.signerPage .sign-img img {
    width: 100%;
}

.signerPage .devider {
    margin-bottom: 1em;
}

/* @media only screen and (min-width: 200px) and (max-width: 550px)   {
 
    .signerPage .sign-img {
        position: relative;
        background-color: white;
        width: 800px;
    }
  
  } */

@media only screen and (min-width: 200px) and (max-width: 767px) {

    .VerificationModal .modal-dialog {
        width: inherit !important;
        top: 30% !important;
        margin: 0px auto !important;
    }

}


/* .AgreementModal .modal-content {
    padding: 0;
    width: 640px;
    height: 480px;
}
.AgreementModal .modal-dialog {
    margin-left:310px;
} */
.AgreementModal .modal-content {
    padding-bottom: 20px;
    padding-left: 0px;
    padding-right: 0px;
}

.AgreementModal .close-button {
    margin-top: -1%;
    font-size: 40px;
    font-weight: 900;
}

/* this is strange */
.AgreementModal .language-container {
    /* margin-left: 480px;
    margin-top: -23px; */
    display: flex;
    align-items: center;
}

/* .VerificationModal .modal-content{
    padding: 0;
    width: 400px;
    height: 230px;
    margin-left: 150px !important;
    margin-top: 140px !important;
}
.VerificationModal .modal-dialog{
    margin-left: 270px;
} */

.VerificationModal .modal-content {
    padding-bottom: 20px;
    padding-left: 0px;
    padding-right: 0px;
}

.VerificationModal .modal-dialog {
    width: 35%;
    top: 25%;
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

.ContractModal .modal-content {
    padding: 0;
}

.ContractModal .modal-dialog {
    width: 700px !important;
}

.LoadingModal .modal-content {
    width: 450px !important;
    height: 320px !important;
    margin-left: 150px !important;
    margin-top: 140px !important;
}

.signerPage .header-details {
    text-align: left;
    width: 800px;
}

.signerPage .header-details-nav {
    text-align: left;
    width: 800px;
    background-color: white;
}

.signerPage .nav-signer {
    background-color: white;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

@media screen and (max-width: 768px) {
    .signerPage .header-details-nav {
        text-align: left;
        width: 100% !important;
        background-color: white;

    }

    .signerPage .header-details {
        text-align: left;
        width: 100% !important;
        display: block;
    }

    .signerPage .sign-img {
        background-color: white;
        width: 100% !important;
        overflow: scroll;

    }

    .signerPage .header {
        width: 100% !important;
        padding: 0px 12px;
    }

    .signerPage .footernav {
        width: 100% !important;
    }

    .signerPage .nav-signer {
        width: 800px !important;
    }

    .signerPage .content {
        width: 800px !important;
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .signerPage .footer-items {
        display: flex;
        justify-content: center;
        margin: 15px;
        padding: 0 6%;
    }

    .signerPage .footer-items img {
        width: 120px;
        height: 35px;

    }

    .signerPage footer {
        position: fixed;
        left: 0;
        bottom: 5vh;
        width: 100%;
        background-color: white;
        color: white;
        height: 60px;
        padding-left: 0 !important;
        padding-right: 0 !important;
    }
}

.signerPage .header-details-nav h4 {
    font-weight: bolder;
}

.signerPage .header-details-nav h6 {
    padding-top: 20px;
    margin-top: -5px;
}


.signerPage .dropdown {
    list-style: none !important;
}

/* media queries for fa-globe */
@media only screen and (min-width: 150px) and (max-width: 240px) {
    .footernav .lp-text-btn .fa-globe {
        font-size: 0.8rem !important;
        margin-right: 12px !important;
    }

    .footernav .lp-text-btn {
        font-size: 0.8rem !important;
        margin-left: 12px !important;
    }

    .footernav h4 {
        font-size: 1.0rem !important;
        /* padding: 12px 0px; */

    }

}

@media only screen and (min-width: 241px) and (max-width: 320px) {
    .footernav .lp-text-btn .fa-globe {
        font-size: 1.2rem !important;
        margin-right: 12px !important;
    }

    .footernav .lp-text-btn {
        font-size: 1.2rem !important;
        /* margin-left: 12px !important; */
    }

    .footernav h4 {
        font-size: 1.4rem !important;
    }
}

@media screen and (min-width: 321px) and (max-width: 831px) {
    .footernav .lp-text-btn .fa-globe {
        font-size: 1.6rem !important;
        margin-right: 22px;
    }

    .footernav .lp-text-btn {
        font-size: 1.6rem !important;
        /* margin-left: 12px !important; */
    }

    .footernav h4 {
        font-size: 1.8rem !important;
        /* padding: 12px 0px; */

    }

}

@media screen and (min-width: 180px) and (max-width: 831px) {
    .signerPage .nav-signer {
        /* padding: 22px 0px !important; */
    }

    .footernav .lp-text-btn {
        margin: 15px 0px 0px 0px !important;
    }

    .footernav {
        padding: 0px 12px;
    }

}