/*     General overwrite     */
#loader {
  position: absolute;
  display: block;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  z-index: 1;
  @include transform-translate-y(-50%);
  text-align: center;
  border-radius: 50%;
  width: 45px;
  height: 45px;
  top: 50%;
}
.main-panel-maps {
  > div {
    height: 100%;
  }

  .map {
    position: relative;
    width: 100%;
    height: calc(100% - 60px);
  }
}
body,
.wrapper {
  min-height: 100vh;
  position: relative;
}

body {
  background-color: $smoke-bg;
}

a {
  color: $info-color;

  &:hover,
  &:focus {
    color: $info-states-color;
    text-decoration: none;
  }
}

a:focus,
a:active,
button::-moz-focus-inner,
input::-moz-focus-inner,
input[type="reset"]::-moz-focus-inner,
input[type="button"]::-moz-focus-inner,
input[type="submit"]::-moz-focus-inner,
select::-moz-focus-inner,
input[type="file"] > input[type="button"]::-moz-focus-inner {
  outline: 0;
}
.ui-slider-handle:focus,
.navbar-toggle,
input:focus {
  outline: 0 !important;
}

/*           Animations              */

.animation-transition-general {
  @include transition($general-transition-time, $transition-linear);
}

.animation-transition-fast,
.sidebar .nav .collapse a > span,
.sidebar .sidebar-wrapper > .nav p,
.sidebar .user .info > a > span,
.sidebar .logo a.logo-normal,
.sidebar .user .info .collapse .nav a > span {
  @include transition($fast-transition-time, $transition-linear);
}

.animation-transition-ultra-fast {
  @include transition($ultra-fast-transition-time, $transition-ease-in);
}

.form-control,
.input-group-addon,
.tagsinput,
.navbar .alert,
.panel-collapse.collapse-hover {
  @include transition($general-transition-time, $transition-linear);
}

.sidebar .nav a,
.table > tbody > tr .td-actions .btn,
.caret {
  @include transition($fast-transition-time, $transition-ease-in);
}

.btn {
  @include transition($ultra-fast-transition-time, $transition-ease-in);
}
.fa {
  width: 18px;
  text-align: center;
}
.margin-top {
  margin-top: 50px;
}

a[aria-selected="true"][aria-expanded="true"] .caret,
a[data-toggle="collapse"][aria-expanded="true"] .caret,
a[aria-expanded="true"] .caret,
.btn[data-toggle="collapse"][aria-expanded="true"] .caret,
a.dropdown-toggle[aria-expanded="true"] .caret,
.rotate-180 {
  @include rotate-180();
}

legend {
  font-size: $font-size-h4;
  font-weight: $font-weight-light;
}

.full-screen-map {
  position: relative;
  width: 100%;
  height: calc(100% - 60px);
}

#loader {
  position: absolute;
  display: block;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  z-index: 1;
  @include transform-translate-y(-50%);
  text-align: center;
  border-radius: 50%;
  width: 45px;
  height: 45px;
  top: 50%;
}

.list-group{
  .list-group-item{
    background-color: rgba(37, 80, 172, 0.16);
    color: #2550AC;
    border: none !important;
    margin-bottom: 8px;
    border-radius: 14px;
    font-weight: 500;
  }
  .active{
    background-color: #2550AC;
    color:#fff;
  }
}

.control-panel{
  .tab-content{
    .input-group{
      position: relative;
      display: flex;
      flex-wrap: nowrap;
    }
  }
}

input[type="range"]{
  display: block;
  width: 100%;
  appearance: auto;
}

.modal-header{
  .modal-title{
    font-size: 18px;
    font-weight: 600;
    margin: 0px;
  }
}