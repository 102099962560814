.landingPageWrapp .ftrWdt h4 {
    font-size: 18px;
    text-transform: uppercase;
    opacity: .4;
    margin-bottom: 69px;
    margin-top: 0;
}
.landingPageWrapp .ftrLinks {
    margin: 16px 0 0 0;
}
.landingPageWrapp .ftrLinks li + li {
    margin-top: 16px;
}
.landingPageWrapp .ftrLinks li a {
    font-size: 16px;
    color: var(--secondary);
    display: inline-block;
    position: relative;
    transition: all 0.3s ease 0s;
}
.landingPageWrapp .ftrLinks li a::before {
    background-color: var(--primary);
    position: absolute;
    content: "";
    width: 10px;
    height: 10px;
    border-radius: 10px;
    left: -10px;
    top: 8px;
    opacity: 0;
    transition: all 0.3s ease 0s;
}
.landingPageWrapp .ftrLinks li a:hover,
.landingPageWrapp .ftrLinks li.current a,
.landingPageWrapp .ftrLinks li.current a:hover {
    color: var(--secondary);
    font-family: "ExtraBold" !important;
    padding-left: 22px;
}
.landingPageWrapp .ftrLinks li.current a::before,
.landingPageWrapp .ftrLinks li a:hover::before {
    left: 0;
    opacity: 1;
}

.landingPageWrapp .ftrCopy {
    background-color: var(--primary);
    text-align: center;
    padding: 20px 0;
}
.landingPageWrapp .ftrCopy p {
    font-size: 16px;
    color: var(--white);
    font-weight: 500;
    margin: 0;
}
.landingPageWrapp .ftrCopy p a {
    text-transform: uppercase;
    font-family: "Bold";
    color: var(--white);
}

@media (max-width:767px) {
    .landingPageWrapp .ftrWdt h4 {
        font-size: 16px;
        margin: 24px 0;
    }
    .landingPageWrapp .ftrCopy {
        padding: 12px 0;
    }
}