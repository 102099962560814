/*      Checkbox and radio         */
.radio {
  margin-bottom: 5px;
}

.checkbox label,
.radio label {
  display: inline-block;
  position: relative;
  cursor: pointer;
  padding-left: 24px;
  margin-bottom: 0;
  line-height: 20px;

  span {
    font-size: 14px;
    display: block;
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    font-weight: 600;
  }
}

.checkbox__table_list_container {
  height: 20px;
}

.checkbox__table_list {
  top: -34px;
  left: -1px;

  &.top-position {
    top: -10px
  }
}

.checkbox label::after {
  font-family: "FontAwesome";
  content: "\f096";
  display: inline-block;
  position: absolute;
  width: 20px;
  height: 20px;
  line-height: 20px;
  padding: 0 3px;
  font-size: 20px;
  left: 0;
  color: $medium-gray;
  background: $medium-gray;
  cursor: pointer;
  border: 0;
  border-radius: 5px;
  transition: color 0.2s linear;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.checkbox label.checkList-checkbox-bottom::after {
  bottom: 0px;
}


.bulk-shift-chekbox-style label {
  top: -13px;
}


.checkbox input[type="checkbox"],
.radio input[type="radio"] {
  opacity: 0;
  margin-left: -12px;
}

.checkbox input[type="checkbox"]:checked+label::after {
  font-family: "FontAwesome";
  font-style: italic;
  content: "\f00c";
}

.checkbox input[type="checkbox"]:checked+label::after {
  color: $white-color;
  background-color: $color-blue-v2;
  font-size: $font-size-base;
}

.checkbox input[type="checkbox"]:checked+label::before {
  opacity: 0;
}

.checkbox input[type="checkbox"]:disabled+label,
.radio input[type="radio"]:disabled+label,
.checkbox input[type="checkbox"]:disabled:checked+label::after {
  color: $medium-gray;
}

.checkbox input[type="checkbox"]:disabled+label::before,
.checkbox input[type="checkbox"]:disabled+label::after {
  cursor: not-allowed;
}

.checkbox input[type="checkbox"]:disabled+label,
.radio input[type="radio"]:disabled+label {
  cursor: not-allowed;
}

.checkbox.checkbox-circle label::before {
  border-radius: 50%;
}

.checkbox.checkbox-inline {
  padding-left: 0;
}

.checkbox-primary input[type="checkbox"]:checked+label::before {
  border-color: $color-blue-v2;
}

.checkbox-primary input[type="checkbox"]:checked+label::after {
  color: #fff;
}

.checkbox-danger input[type="checkbox"]:checked+label::before {
  background-color: #d9534f;
  border-color: #d9534f;
}

.checkbox-danger input[type="checkbox"]:checked+label::after {
  color: #fff;
}

.checkbox-info input[type="checkbox"]:checked+label::before {
  background-color: #5bc0de;
  border-color: #5bc0de;
}

.checkbox-info input[type="checkbox"]:checked+label::after {
  color: #fff;
}

.checkbox-warning input[type="checkbox"]:checked+label::before {
  background-color: #f0ad4e;
  border-color: #f0ad4e;
}

.checkbox-warning input[type="checkbox"]:checked+label::after {
  color: #fff;
}

.checkbox-success input[type="checkbox"]:checked+label::before {
  background-color: #5cb85c;
  border-color: #5cb85c;
}

.checkbox-success input[type="checkbox"]:checked+label::after {
  color: #fff;
}

.radio label::before,
.radio label::after {
  font-family: "FontAwesome";
  content: "\f111";
  font-size: 14px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  display: inline-block;
  position: absolute;
  line-height: 20px;
  left: 0;
  top: 0;
  color: $medium-gray;
  padding: 1px;
  transition: color 0.2s linear;
}

//commented as we will same fontawesome component// 
// .radio input[type="radio"]:checked + label::after {
//   font-family: "FontAwesome";
//   content: "\f111";
//   color: $medium-gray;
// }

.radio input[type="radio"]:checked:not(:disabled):hover+label::after,
.radio input[type="radio"]:checked+label::after {
  color: $color-blue-v2 !important;
}

.radio input[type="radio"]:disabled+label {
  color: #ddd;
}

.radio input[type="radio"]:disabled+label::before,
.radio input[type="radio"]:disabled+label::after {
  color: #ddd;
}

.radio.radio-inline {
  margin-top: 0;
}

.bootstrap-switch {
  display: inline-block;
  direction: ltr;
  cursor: pointer;
  border-radius: 30px;
  border: 0;
  position: relative;
  text-align: left;
  overflow: hidden;
  margin-bottom: 5px;
  line-height: 8px;
  width: 61px !important;
  height: 26px;
  outline: none;
  z-index: 0;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  vertical-align: middle;
  -webkit-transition: border-color ease-in-out 0.15s,
    box-shadow ease-in-out 0.15s;
  transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
}

.bootstrap-switch .bootstrap-switch-container {
  display: inline-flex;
  top: 0;
  height: 26px;
  border-radius: 4px;
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
  width: 100px !important;
}

.bootstrap-switch .bootstrap-switch-handle-on,
.bootstrap-switch .bootstrap-switch-handle-off,
.bootstrap-switch .bootstrap-switch-label {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  cursor: pointer;
  display: inline-block !important;
  height: 100%;
  color: #fff;
  padding: 6px 12px;
  font-size: 11px;
  text-indent: -5px;
  line-height: 15px;
  -webkit-transition: 0.25s ease-out;
  transition: 0.25s ease-out;
}

.bootstrap-switch .bootstrap-switch-handle-on,
.bootstrap-switch .bootstrap-switch-handle-off {
  text-align: center;
  z-index: 1;
  float: left;
  width: 50% !important;
  background-color: $default-color;
}

.bootstrap-switch .bootstrap-switch-handle-on.bootstrap-switch-primary,
.bootstrap-switch .bootstrap-switch-handle-off.bootstrap-switch-primary {
  color: #fff;
  background: $primary-color;
}

.bootstrap-switch .bootstrap-switch-handle-on.bootstrap-switch-info,
.bootstrap-switch .bootstrap-switch-handle-off.bootstrap-switch-info {
  color: #fff;
  background: $color-blue;
}

.bootstrap-switch .bootstrap-switch-handle-on.bootstrap-switch-success,
.bootstrap-switch .bootstrap-switch-handle-off.bootstrap-switch-success {
  color: #fff;
  background: $color-green;
}

.bootstrap-switch .bootstrap-switch-handle-on.bootstrap-switch-warning,
.bootstrap-switch .bootstrap-switch-handle-off.bootstrap-switch-warning {
  background: $color-orange;
  color: #fff;
}

.bootstrap-switch .bootstrap-switch-handle-on.bootstrap-switch-danger,
.bootstrap-switch .bootstrap-switch-handle-off.bootstrap-switch-danger {
  color: #fff;
  background: $color-red;
}

.bootstrap-switch .bootstrap-switch-handle-on.bootstrap-switch-default,
.bootstrap-switch .bootstrap-switch-handle-off.bootstrap-switch-default {
  color: #fff;
  background: $medium-gray;
}

.bootstrap-switch .bootstrap-switch-label {
  text-align: center;
  z-index: 100;
  color: #333333;
  background: #ffffff;
  width: 22px !important;
  height: 22px;
  margin: 2px -11px;
  border-radius: 12px;
  position: relative;
  float: left;
  padding: 0;
  background-color: #ffffff;
  box-shadow: 0 1px 1px #ffffff inset, 0 1px 1px rgba(0, 0, 0, 0.25);
}

.bootstrap-switch .bootstrap-switch-handle-on {
  border-bottom-left-radius: 3px;
  border-top-left-radius: 3px;
}

.bootstrap-switch .bootstrap-switch-handle-off {
  text-indent: 6px;
}

.bootstrap-switch input[type="radio"],
.bootstrap-switch input[type="checkbox"] {
  position: absolute !important;
  top: 0;
  left: 0;
  opacity: 0;
  filter: alpha(opacity=0);
  z-index: -1;
}

.bootstrap-switch input[type="radio"].form-control,
.bootstrap-switch input[type="checkbox"].form-control {
  height: auto;
}

.bootstrap-switch.bootstrap-switch-mini .bootstrap-switch-handle-on,
.bootstrap-switch.bootstrap-switch-mini .bootstrap-switch-handle-off,
.bootstrap-switch.bootstrap-switch-mini .bootstrap-switch-label {
  padding: 1px 5px;
  font-size: 12px;
  line-height: 1.5;
}

.bootstrap-switch.bootstrap-switch-small .bootstrap-switch-handle-on,
.bootstrap-switch.bootstrap-switch-small .bootstrap-switch-handle-off,
.bootstrap-switch.bootstrap-switch-small .bootstrap-switch-label {
  padding: 5px 10px;
  font-size: 12px;
  line-height: 1.5;
}

.bootstrap-switch.bootstrap-switch-large .bootstrap-switch-handle-on,
.bootstrap-switch.bootstrap-switch-large .bootstrap-switch-handle-off,
.bootstrap-switch.bootstrap-switch-large .bootstrap-switch-label {
  padding: 6px 16px;
  font-size: 18px;
  line-height: 1.33;
}

.bootstrap-switch.bootstrap-switch-disabled,
.bootstrap-switch.bootstrap-switch-readonly,
.bootstrap-switch.bootstrap-switch-indeterminate {
  cursor: default !important;
}

.bootstrap-switch.bootstrap-switch-disabled .bootstrap-switch-handle-on,
.bootstrap-switch.bootstrap-switch-readonly .bootstrap-switch-handle-on,
.bootstrap-switch.bootstrap-switch-indeterminate .bootstrap-switch-handle-on,
.bootstrap-switch.bootstrap-switch-disabled .bootstrap-switch-handle-off,
.bootstrap-switch.bootstrap-switch-readonly .bootstrap-switch-handle-off,
.bootstrap-switch.bootstrap-switch-indeterminate .bootstrap-switch-handle-off,
.bootstrap-switch.bootstrap-switch-disabled .bootstrap-switch-label,
.bootstrap-switch.bootstrap-switch-readonly .bootstrap-switch-label,
.bootstrap-switch.bootstrap-switch-indeterminate .bootstrap-switch-label {
  opacity: 0.5;
  filter: alpha(opacity=50);
  cursor: default !important;
}

.bootstrap-switch.bootstrap-switch-animate .bootstrap-switch-container {
  -webkit-transition: margin-left 0.5s;
  transition: margin-left 0.5s;
}

.bootstrap-switch.bootstrap-switch-inverse .bootstrap-switch-handle-on {
  border-bottom-left-radius: 0;
  border-top-left-radius: 0;
  border-bottom-right-radius: 3px;
  border-top-right-radius: 3px;
}

.bootstrap-switch.bootstrap-switch-inverse .bootstrap-switch-handle-off {
  border-bottom-right-radius: 0;
  border-top-right-radius: 0;
  border-bottom-left-radius: 3px;
  border-top-left-radius: 3px;
}

.bootstrap-switch.bootstrap-switch-focused {
  // border-color: #66afe9;
  // outline: 0;
  // -webkit-box-shadow: inset 0 1px 1px rgba(0,0,0,.075), 0 0 8px rgba(102, 175, 233, 0.6);
  // box-shadow: inset 0 1px 1px rgba(0,0,0,.075), 0 0 8px rgba(102, 175, 233, 0.6);
}

// .bootstrap-switch.bootstrap-switch-on .bootstrap-switch-label,
// .bootstrap-switch.bootstrap-switch-inverse.bootstrap-switch-off .bootstrap-switch-label {
//   border-bottom-right-radius: 3px;
//   border-top-right-radius: 3px;
// }
// .bootstrap-switch.bootstrap-switch-off .bootstrap-switch-label,
// .bootstrap-switch.bootstrap-switch-inverse.bootstrap-switch-on .bootstrap-switch-label {
//   border-bottom-left-radius: 3px;
//   border-top-left-radius: 3px;
// }

.bootstrap-switch.bootstrap-switch-on .bootstrap-switch-container {
  margin-left: -2px !important;
}

.bootstrap-switch.bootstrap-switch-off .bootstrap-switch-container {
  margin-left: -37px !important;
}

.bootstrap-switch.bootstrap-switch-on:hover .bootstrap-switch-label {
  width: 26px !important;
  margin: 2px -15px;
}

.bootstrap-switch.bootstrap-switch-off:hover .bootstrap-switch-label {
  width: 26px !important;
  margin: 2px -15px -13px -11px;
}

.bootstrap-switch.bootstrap-switch-on .bootstrap-switch-handle-off {
  background-color: $default-color;
}

.bootstrap-switch.bootstrap-switch-off .bootstrap-switch-handle-on {
  background-color: $medium-gray;
}

.bootstrap-switch.bootstrap-switch-on .bootstrap-switch-danger~.bootstrap-switch-default {
  background-color: $color-red;
}

.bootstrap-switch.bootstrap-switch-on .bootstrap-switch-warning~.bootstrap-switch-default {
  background-color: $color-orange;
}

.bootstrap-switch.bootstrap-switch-on .bootstrap-switch-success~.bootstrap-switch-default {
  background-color: $color-green;
}

.bootstrap-switch.bootstrap-switch-on .bootstrap-switch-primary~.bootstrap-switch-default {
  background-color: $primary-color;
}

.bootstrap-switch.bootstrap-switch-on .bootstrap-switch-info~.bootstrap-switch-default {
  background-color: $color-blue;
}

.bootstrap-switch.bootstrap-switch-off .bootstrap-switch-danger,
.bootstrap-switch.bootstrap-switch-off .bootstrap-switch-primary,
.bootstrap-switch.bootstrap-switch-off .bootstrap-switch-info,
.bootstrap-switch.bootstrap-switch-off .bootstrap-switch-warning,
.bootstrap-switch.bootstrap-switch-off .bootstrap-switch-success {
  background-color: $medium-gray;
}

// custom button checkbox css //
.checkbox-button .round {
  cursor: pointer;
}

.checkbox-button .round input[type="checkbox"]:disabled+span {
  cursor: no-drop;
  opacity: 0.5;
}

.checkbox-button .round input[type="checkbox"] {
  display: none;
}

.checkbox-button .round span {
  position: relative;
  display: inline-block;
  background-color: #F3F4ED;
  color: #717171;
  padding: 5px;
  border-radius: 10px;
  min-width: 60px;
  overflow: hidden;
  font-weight: 600;
  font-size: 12px;
}

.checkbox-button .round input[type="checkbox"]:checked~span {
  background-color: #2550AC;
  color: #ffffff;
}

.checkbox-button .round .isSatSun {
  background-color: #df4733;
  color: #ffffff;
}

.checkbox-button .round {
  .overflow-property {
    max-width: 80px;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}

@media screen and (width >=1590px) {
  .checkbox__table_list {
    &.top-position {
      top: -34px;
    }
  }
}

@media screen and (width < 1256px) {
  .checkbox__table_list {
    &.top-position {
      top: -34px;
    }
  }
}

@media screen and (1300px < width < 1516px) {
  .checkbox__table_list {
    &.top-position {
      top: -34px;
    }
  }
}