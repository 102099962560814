.main-panel{
  .main-content {
    background: #ffffff;
    // border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 24px;
    padding: 25px;
    &_fixed-height{
      max-height: calc(100vh - 100px);
      min-height: 300px;
      height: 100%;
      position: relative;
      padding-top: 0;
      padding-bottom: 0;
    }
    &__footer{
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      z-index: 200;
      padding: 0 10px;
      background-color: #fff;
      border: 1px solid #447df7;
      border-radius: 2px;
    }
  }
}
.page{
  display: flex;
  flex-direction: column;
  width: 100%;
  position: relative;
  height: 100%;
  &__header{
    display: block;
    width: 100%;
    padding: 0 10px;
    margin-bottom: 10px;
  }
  &__title{
    font-size: 24px;
    margin: 0;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    padding-bottom: 20px;
    width: 100%;
  }
  &__content{
    display: block;
  }
  &_overflow{
    overflow: auto;
    padding-top: 20px;
    .page__content{
      padding-bottom: 60px;
    }
  }
}
.flex-box{
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: flex-start;
  &_v-center{
    align-items: center;
  }
  &_v-end{
    align-items: flex-end;
  }
  &_h-center{
    justify-content: center;
  }
  &_h-end{
    justify-content: flex-end;
  }
  & > .radio{
    margin: 5px 10px;
    &:first-child{
      margin-left: 0;
    }
    &:last-child{
      margin-right: 0;
    }
  }
}
.input-area{
  padding-top: 10px;
  margin-top: -19px;
  &__label{
    display: inline-block;
    position: relative;
    margin-left: 10px;
    top: 13px;
    max-width: calc(100% - 20px);
    background-color: #ffffff;
    padding-left: 4px;
    font-size: 14px;
    line-height: 19px;
    font-weight: 400;
    letter-spacing: 0.45px;
    z-index: 2;
    color: #818181;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  &__content{
    background-color: #ffffff;
    border: 1px solid #cccccc;
    border-radius: 4px;
    color: #565656;
    padding: 15px 12px;
    box-shadow: none;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    min-height: 56px;
  }
}
.read-area{
  display: flex;
  flex-direction: column;
  font-size: 14px;
  font-weight: 600;
  border-left: 1px solid #cccccc;
  padding-left: 10px;
  overflow: hidden;
  &__label{
    display: inline-block;
    background-color: #ffffff;
    font: inherit;
    padding: 0 5px;
    line-height: 24px;
    letter-spacing: 0.45px;
    color: #818181;
  }
  &__content{
    background-color: #ffffff;
    color: #565656;
    box-shadow: none;
    display: flex;
    padding: 0 5px;
    align-items: center;
    font: inherit;
    font-size: 90%;
    flex-wrap: wrap;
  }
}
.disabled-input {
  .input-area__content {
    color: #818181;
    font-weight: bold;
  }
}
.custom-big-modal {
  width:90% !important;
}

.modal.right .modal-dialog {
  position: fixed;
  margin: auto;
  width: calc(100% - 300px);  
  height: 100%;
  -webkit-transform: translate3d(0%, 0, 0);
      -ms-transform: translate3d(0%, 0, 0);
       -o-transform: translate3d(0%, 0, 0);
          transform: translate3d(0%, 0, 0);
}

.modal.right .modal-content {
  height: 100%;
  overflow-y: auto;
  border-radius: 0;
}
      
/*Right*/
.modal.right.fade .modal-dialog {
  right: 0 !important;
  min-width: 80%;
  -webkit-transition: opacity 0.3s linear, right 0.3s ease-out;
     -moz-transition: opacity 0.3s linear, right 0.3s ease-out;
       -o-transition: opacity 0.3s linear, right 0.3s ease-out;
          transition: opacity 0.3s linear, right 0.3s ease-out;
}
.modal.right.fade.filter .modal-dialog {
  right: 0 !important;
  width: 30% !important;
  min-width: 0;
  -webkit-transition: opacity 0.3s linear, right 0.3s ease-out;
     -moz-transition: opacity 0.3s linear, right 0.3s ease-out;
       -o-transition: opacity 0.3s linear, right 0.3s ease-out;
          transition: opacity 0.3s linear, right 0.3s ease-out;
}

.modal.right.fade.in .modal-dialog {
  right: 0 !important;
}

@media screen and (width <=720px) {
  .modal.right.fade .modal-dialog {
    min-width: 100%;
  }
}
