@media (max-width:1300px){
    .sidebar {
        display: none !important;
    }

    .main-panel{
        position: relative !important;
        float: right !important;
        width: 100% !important;
        min-height: 100% !important;
    }
}

@media (min-width: 1300px){
    .wrapper .sidebar {
        width: 260px;
    }

    .sidebar .sidebar-wrapper {
        overflow: auto ;
        position: relative ;
        z-index: 4 ;
        width: 260px ;
        height: calc(100% - 170px) ;
        padding-bottom: 30px ;
        /* box-shadow: inset -1px 0px 0px 0px #cfcfca ; */
    }

    .sidebar .logo {
        padding: 12px 0px;
        margin: 0;
        /* box-shadow: inset -1px 0px 0px 0px #cfcfca; */
        position: relative;
        z-index: 4;
        display: block;
        /* height: 160px; */
    }

    .wrapper  .main-panel{
        position: relative;
        float: right;
        width: calc(100% - 260px);
        min-height: 100%;
    }
}


@media (min-width: 1400px){
    .wrapper .sidebar {
        width: 260px;
    }
    .sidebar .sidebar-wrapper {
        overflow: auto;
        position: relative;
        z-index: 4;
        width: 260px;
        height: calc(100% - 170px);
        padding-bottom: 30px;
        /* box-shadow: inset -1px 0px 0px 0px #cfcfca; */
    }

    .sidebar .logo {
        padding: 12px 0px;
        margin: 0;
        /* box-shadow: inset -1px 0px 0px 0px #cfcfca; */
        position: relative;
        z-index: 4;
        display: block;
    }

    .wrapper  .main-panel{
        position: relative;
        float: right;
        width: calc(100% - 260px);
        min-height: 100%;
    }
}

@media (min-width: 1700px){
    .wrapper .sidebar {
        width: 300px;
    }

    .sidebar .sidebar-wrapper {
        overflow: auto;
        position: relative;
        z-index: 4;
        width: 300px;
        height: calc(100% - 170px);
        padding-bottom: 30px;
        /* box-shadow: inset -1px 0px 0px 0px #cfcfca; */
    }

    .wrapper  .main-panel{
        position: relative;
        float: right;
        width: calc(100% - 300px);
        min-height: 100%;
    }
}


