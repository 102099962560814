form {
  label {
    font-weight:500;
    &.radio,
    &.checkbox {
      font-size: $font-size-base;
      text-transform: none;
      cursor: pointer;
    }
  }
}

fieldset {
  border: 1px solid $medium-gray;
  border-top: 1px solid $medium-gray;
  padding: 1rem 1rem 1rem 1rem;
  background: white;
  margin: 1rem auto 2rem auto;
  border-radius: 14px;
  legend {
    display: inline-block;
    width: auto;
    font-size: 14pt;
    padding: 0rem 1rem;
    color: #ffffff;
    background-color: #2550AC;
    font-weight: 400;
  }
}

.form-horizontal {
  .checkbox,
  .radio {
    padding-top: 0;

    &:first-child,
    &.checkbox-inline,
    &.radio-inline {
      margin-top: 10px;
    }
  }
}

.star {
  color: $danger-color;
  padding-left: 3px;
}

.form-subtitle {
  color: $color-blue-v2;
  padding-bottom:15px;
  font-size: $font-size-base;
  font-weight: $font-weight-bold;
  text-transform: capitalize;
  margin: 5px 0;
}

.row-flex {
  display: flex;
  margin-left: 0;
  margin-right: 0;
}

.custom_style_integration_details {
  // border-top: 3px solid #dddddd;
  .form-subtitle {
    padding-bottom: 0;
  }  

  #root_data {
    display: flex;
    border: none;
    margin: 0;
    padding: 0;
    .form-group {
      width: 50%;
    }
    .field-string {
      padding-right: calc(var(--bs-gutter-x)* .5);
    }
    .field-integer {
      padding-left: calc(var(--bs-gutter-x)* .5);
      padding-right: calc(var(--bs-gutter-x)* .5);
    }
  }
}

@media (min-width: $screen-md-min) {
  .form-horizontal {
    .control-label {
      padding-top: 12px !important;
    }
    code {
      margin-top: 8px;
      display: inline-block;
    }
  }
}

.field-array-of-object, .no-border-around-lists {
  fieldset {
    border:0;
    border-top:0;
    padding:0;
    margin:0;
  }
}

.no-fieldset-title>fieldset>legend{
  display: none;
}
.no-fieldset-title>label{
  display: none;
}

.view-group {
  padding-left: 10px;
  padding-bottom: 10px;
  padding-top: 5px;

  .view-row {
    padding: 5px;
    margin-bottom: 5px;

    &:after {
      content : "";
      display: block;
      height  : 1px;
      width   : 400px;  /* or 100px */
      border-bottom:1px solid #d3d3d3;
    }

    .view-title {
      width:250px;
      min-width: 250px;
      display:inline-block;
    }
    .view-value {

    }
  }
}
