.quill-container {
    display: flex;
    flex-direction: column;
    align-items: center;
}
.quill-container .ql-container.ql-snow {
    border: 0px;
}
.contract-container {
    border: 1px solid black;
}
.contract-container .head-txt {
    width: 100%;
    border-bottom: 1px solid black;   
    padding: 1em 0;
    text-align: center;
}
.contract-container .head-txt h5{
    line-height: 1;
    margin: 0;

}
.quill-container .ready-btn {
    width: 100%;
    display: flex;
    justify-content: flex-end;  
    padding: 1em; 
}
.contract-header {
    display: flex;
    justify-content: space-between;
    padding-bottom: 1em;
}
.contract-header .backbtn {
    color: #447df7;
    cursor: pointer;
}
.contract-header .actions {
    display: flex;
    justify-content: space-between;
}
.contract-header .actions .download{
    margin-left: 1em;
}
/* .replace-body {
    width: 70%;
    margin: auto;
} */

.replace-body {
    width: 70%;
    margin: 1.1em auto 2em auto;
}
.replace-body input {
    width: 100%;
    border: 1px solid rgba(0, 0, 0, 0.125);
    border-radius: .5em;
    padding: 1em;
    margin: 1em 0 .5em 0;
} 
.replace-body .redirect-btns .nxt{
    float: right;
} 
.contract-pdf {
    padding-left: 1em;
}
.contract-pdf .header_box{
    height: 60px;
    border-bottom: 1px solid red;
    margin-bottom: 12px;
}
.contract-pdf .ql-container.ql-snow {
    border: 0px;
}
.contract-pdf .ql-editor {
    padding: 0;
}
.docSpinner .loading-spinner{
    height: 60px !important;
    width: 60px !important;
    border-width: 4px;
}