.landingPageWrapp .officeInfoCol {
    text-align: center;
}
.landingPageWrapp .officeInfoImg {
    margin-bottom: 16px;
}
.landingPageWrapp .officeInfoImg img {
    max-width: 100%;
}
.landingPageWrapp .officeInfoDes h3 {
    font-size: 28px;
    margin-bottom: 12px;
}
.landingPageWrapp .officeInfoDes p {
    font-size: 18px;
    line-height: 34px;
    margin: 0;
}

@media (max-width:1199px) {
    .landingPageWrapp .officeInfoDes h3 {
        font-size: 24px;
        margin-bottom: 12px;
    }
}

@media (max-width:767px) {
    .landingPageWrapp .officeInfoDes p {
        font-size: 16px;
        line-height: 30px;
    }
}