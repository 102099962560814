.fullscreen {
  .full-screenable-node {
    width: 100%;
    height: 100%;
    padding: 30px 20px;
    box-sizing: border-box;
    &.locked-page{
      display: block;
      &_block{
        position: absolute;
        top: 0;
        right: 0;
        left: 0;
        bottom: 0;
        overflow: auto;
        padding: 0;
        box-sizing: border-box;
        width: 100%;
        .locked-page__inner{
          border-radius: 0;
          box-shadow: none;
        }
      }
    }
  }
}
.locked-page {
  &__inner{
    overflow: auto;
    height: 100%;
    border-radius: 5px;
    background-color: #ffffff;
    margin: auto;
    width: 100%;
    max-width: 100%;
    // box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.2);
    display: flex;
    flex-direction: column;
  }
  &__content{
    padding: 20px;
    width: 100%;
    margin: auto 0;
    &.mode__status{
      padding-bottom: 100px;
      margin: 0;
    }
    &.mode__userpage{
      padding-bottom: 100px;
      margin: 0;
      flex-grow: 1;
    }
    &.mode__unlocked{
      .locked-init-screen__content{
        padding: 0;
      }
      .locked-init-screen__content-fields{
      }
    }
  }
  &__header{
    padding: 10px 20px;
    border-bottom: 2px solid #447df7;
    width: 100%;
    font-size:1em;
    display: flex;
    align-items: center;
    flex-wrap: wrap;

    &-col{
      padding: 0 10px;
      box-sizing: border-box;
      flex-grow: 1;
      h4{
        margin: 0;
        color: #447df7;
        font-size: 22px;
        font-weight: 600;
        &:first-letter{
          text-transform: uppercase;
        }
      }
      small{
        color: rgba(119,119,119,0.7);
        font-size: 14px;
        font-weight: 600;
      }
      i{
        font-size: 30px;
        font-weight: 600;
      }
      &_no-grow{
        flex-grow: 0;
      }
      &_left{
        margin-left: auto;
      }
      .simple-clock{
        color: #447df7;
        font-size: 20px;
        font-weight: 900;
      }
    }
  }

  &__input{
    display: flex;
    flex-direction: column;
    span{
      font-size: 18px;
      font-weight: 900;
      padding-left: 10px;
      margin-bottom: 5px;
    }
    input{
      height: 56px;
      padding: 10px 20px;
      font-size: 18px;
      border: 2px solid #447df7;
      border-radius: 10px;
      background-color: #ffffff;
      font-weight: 900;
      &::placeholder{
        color: #C4C4C4;
      }
    }
  }
  &__footer{
    position: absolute;
    bottom: 0;
    right: 0;
    left: 0;
    padding: 5px 20px;
    box-sizing: border-box;
    border-top: 2px solid #447df7;
    display: flex;
    flex-wrap: wrap;
    background-color: #ffffff;
    &-btn{
      height: 40px;
      width: 40px;
      display: flex;
      align-items: center;
      justify-content: center;
      border: 2px solid #447df7;
      border-radius: 50%;
      background-color: #fff;
      i{
        font-size: 20px!important;
        color: #447df7;
      }
    }
    &-nav{
      display: flex;
      align-items: center;
      padding-right: 20px;
      margin-right: 20px;
      margin-bottom: 5px;
      margin-top: 5px;
      border-right: 2px solid #447df7;
    }
    &-buttons{
      display: flex;
      flex-wrap: wrap;
      flex-grow: 1;
      justify-content: space-between;
      & > button{
        margin: 5px;
      }
    }
    &-left-time{
      color: #447df7;
      display: flex;
      align-items: center;
      padding-left: 10px;
      button{
        font-weight: 900;
        text-decoration: underline;
        border: none;
        outline: none;
        border-radius: 0;
        background-color: transparent;
      }
      span{
        font-weight: 600;
      }
    }
    &_status{
      .form-group{
        flex-grow: 1;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        margin: 0;
        h4{
          margin: 0;
          white-space: nowrap;
          padding: 5px 10px;
        }
        div{
          padding: 5px 10px;
        }
        input{
          min-width: 200px;
          width: auto;
          flex-grow: 1;
          margin: 5px 10px;
        }
      }
    }
  }
  &.is_safari{
    bottom: 30px!important;
    .locked-page__footer{
      bottom: 30px;
    }
  }
  .employee-table__user div h4 {
    font-size:0.8em !important;
  }
  .employee-table__user div small {
    font-size:0.75em !important;
  }
  .employee-table .rt-tbody .rt-tr-group .rt-tr .rt-td {
    font-size: 0.8em !important;
  }
  .employee-table__start span {
    font-size:0.8em !important;
  }
  .locked-table {
    .rt-resizable-header-content {
      font-size: 1.1em !important;
      font-weight: 900;
    }
    .rt-tbody .rt-tr-group .rt-tr .rt-td{
      font-size: 1.1em !important;
    }
  }
  .rt-td {
    white-space: normal;
    overflow: hidden;

  }
  .employee-table__status {
    font-size:0.8em !important;
    white-space: normal;
    float: left;
  }
  .employee-table__user i {
    min-height: 40px;
    min-width: 40px;
    max-height: 40px;
    max-width: 40px;
  }
}
.locked-mode-status{
  margin-top: 10px;
  h5{
    font-size: 18px;
    font-weight: 900;
    padding-left: 10px;
    margin-bottom: 10px;
    line-height: 1.4;
  }
  .react-select__control{
    min-height: 50px;
    border-width: 2px;
    border-radius: 10px;
    border-color: #447df7;
    &:hover{
      border-color: #447df7;
      opacity: 0.9;
    }
  }
  .react-select__value-container{
    padding: 5px 20px;
    font-weight: 800;
    font-size: 16px;
  }
  .react-select__option{
    padding: 10px 20px;
    font-size: 16px;
    font-weight: 800;
  }
}
.locked-mode-user{
  position: relative;
  .container-fluid{
    padding: 0;
    width: 100%;
    margin: 0 0 auto 0;
  }
  &__title{
    margin: auto 0 0 0;
    font-size: 45px;
  }
  &__sub-title{
    font-size: 25px;
    color: rgba(119, 119, 119, 0.8);
    margin: 20px 0;
  }
  &__logout{
    width: 100%;
    margin: auto 0;
    h4{
      margin: 0 0 10px 0;
      font-size: 50px;
      line-height: 1.1;
      font-weight: 600;
      text-align: center;
    }
    &-hint{
      display: block;
      text-align: center;
      width: 100%;
      margin-top: 10px;
      margin-bottom: 10px;
      font-size: 18px;
      color: rgba(119, 119, 119, 0.8);
      font-weight: 600;
      &_small{
        font-size: 16px;
      }
      b{
        margin-top: 10px;
        display: block;
        color: rgba(251, 64, 75, 0.8);
      }
    }
    &-buttons{
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      margin: 0 -10px 10px -10px;
      button{
        margin: 10px;
      }
    }
  }
  .flex-card{
    border-width: 2px;
    min-height: auto;
    padding: 30px 20px;
    border-color: rgba(119, 119, 119, 0.2);
    &__title{
      font-size: 25px;
    }
    &__desc{
      margin-top: 15px;
      font-size: 18px;
      color: rgba(119, 119, 119, 0.8);
    }
    &__header-icon{
      i{
        font-size: 50px;
      }
    }
    &__header{
      &-wrap{
        padding: 0;
      }
    }
  }
}
.locked-login-input {
  font-size:16px;
  label {
    font-weight: bold;
    display: block;
  }
  input {
    padding: 5px;
  }
}

.num-keypad {
  display: flex;
  align-items: center;
  justify-content: center;
  &__col{
    padding: 10px;
    min-width: 20%;
    max-width: 20%;
    flex-basis: 1px;
    &_double{
      min-width: 40%;
      max-width: 40%;
    }
    button{
      height: 100%;
      width: 100%;
      min-height: 60px;
      font-size: 26px;
      font-weight: 900;
      border-width: 2px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
  &__targets{
    width: 100%;
    font-size: 20px;
    font-weight: 900;
    border: 2px solid #447df7;
    display: flex;
    border-radius: 3px;
    overflow: hidden;
  }
  &__target{
    border: none;
    outline: none;
    color: #447df7;
    flex-grow: 1;
    flex-basis: 1px;
    background-color: #fff;
    box-sizing: border-box;
    font-size: 18px!important;

    &_active{
      color: #fff;
      background-color: #447df7;
    }
  }
  &__table{
    display: flex;
    flex-direction: column;
    width: 100%;
  }
  &__row{
    display: flex;
  }
}
.locked-table {
  .rt-thead .rt-th:last-child {
    text-align: left;
  }
  .rt-tbody .rt-tr-group .rt-tr .rt-td {
    text-align: left;
  }
}
.locked-mode-login{
  &__logo{
    padding-left: 15px;
    margin-bottom: 20px;
    max-width: 200px;
    width: 100%;
    box-sizing: border-box;
    height: auto;
  }
  &__content{
    display: flex;
    flex-wrap: wrap;
    margin: 0 -10px;
    padding-bottom: 80px;
    &-left{
      flex-grow: 1;
      padding: 0 30px 30px 30px;
      flex-basis: 300px;
      .locked-page__input{
        margin-bottom: 20px;
      }
    }
    &-right{
      flex-grow: 1;
      flex-basis: 400px;
      padding: 0 20px 0;
      margin: auto;
    }
  }
  &__buttons{
    display: flex;
    flex-grow: 1;
    max-width: 600px;
    button{
      flex-grow: 1;
    }
  }
}
.locked-mode-user{
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
}
.locked-init-screen{
  &__title{
    padding-left: 20px;
    margin: 20px 0 20px 0;
  }
  .locked-page__input{
    margin-bottom: 15px;
  }
  &__content{
    display: flex;
    flex-wrap: wrap;
    margin: 0 -10px;
    padding-bottom: 80px;
    &-fields{
      flex-grow: 1;
      padding: 0 30px 30px 30px;
      flex-basis: 300px;
      .locked-page__input{
        margin-bottom: 20px;
      }
    }
    &-keyboard{
      flex-grow: 1;
      flex-basis: 400px;
      padding: 0 20px 0;
      margin: auto;
    }
  }
  &__project{
    margin-bottom: 20px;
    .managers-widget{
      & > div{
        height: auto;
      }
    }
    & > span{
      display: block;
      font-size: 18px;
      font-weight: 900;
      padding-left: 10px;
      margin-bottom: 5px;
      line-height: 1.4;
    }
    .react-select__control{
      min-height: 56px;
      border-width: 2px;
      border-radius: 10px;
      border-color: #447df7;
      &:hover{
        border-color: #447df7;
        opacity: 0.9;
      }
    }
    .react-select__value-container{
      padding: 5px 20px;
      font-weight: 800;
      font-size: 16px;
    }
    .react-select__option{
      padding: 10px 20px;
      font-size: 16px;
      font-weight: 800;
    }
  }
}
@media screen and (max-width: 876px){
  .locked-page-header {
    flex-direction: column;
    align-items: center;
    &__col{
      padding: 10px 0;
      text-align: center;
    }
  }
}
